import { Box, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { GETAPI } from "../Auth";
import {
  CircularProgressNar,
  isMobile,
  MUITable,
  NameLang,
  TopComp,
} from "../Components.js/common";
import {
  DateText,
  DownloadsText,
  HomeText,
  NoticeBoardText,
  NoticeText,
  PublicationsText,
  PublicationText,
  TitleText,
  ViewText,
} from "../Components.js/Datasets";

export const NoticeBoard = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    loading: true,
    data: [],
  });
  const { data, loading } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[3],
      loading: false,
    }));
  }, [outletContext]);
  const columns = [
    {
      field: "acf",
      headerName: TitleText(EN),
      flex: 1,
      renderCell: (params) => (
        <span className="s-16">
          {EN ? params.value?.name_en : params.value?.name_np}
        </span>
      ),
    },
    {
      field: "date",
      headerName: DateText(EN),
      width: isMobile ? 90 : 200,
      renderCell: (params) => {
        var val = params.value;
        var split = val.split("T");
        return <span className="s-14">{split[0]}</span>;
      },
    },
    {
      field: " ",
      headerName: "",
      width: isMobile ? 90 : 200,
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            navigate("/notice/" + params.row.id, { state: params.row });
          }}
          className="bg-primary-nar c-white btn_trfm p-0 s-14"
          style={{ height: "30px" }}
        >
          {ViewText(EN)}
        </Button>
      ),
    },
  ];
  const handleRowClick = (val) => {
    return;
  };
  return (
    <Box>
      <TopComp
        title={NoticeBoardText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: NoticeBoardText(EN), val: "" },
        ]}
        navigate={navigate}
      />
      <Container className="mt-4">
        {MUITable(data, columns, handleRowClick, loading, "100%", "70vh")}
      </Container>
    </Box>
  );
};

export const OneNotice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const { id } = useParams();
  const [values, setValues] = useState({
    loading: true,
    data: {},
  });
  const { loading, data } = values;
  useEffect(() => {
    if (!location.state) {
      GETAPI("notice/" + id, navigate).then((res) => {
        setValues((x) => ({ ...x, loading: false, data: res }));
      });
    } else setValues((x) => ({ ...x, data: location.state, loading: false }));
  }, [location.state, navigate, id]);
  return (
    <Box>
      <TopComp
        title={NoticeText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: NoticeBoardText(EN), val: "/notices" },
          { name: NoticeText(EN) + " " + id, val: "" },
        ]}
        navigate={navigate}
      />
      {loading && <CircularProgressNar />}
      {data.date && !loading && (
        <Container>
          <p className="mt-4 s-24 w3 mb-2">{NameLang(data, EN)}</p>
          <p className="c-gray">{data.date.split("T")[0]}</p>
          <p
            className="mt-3 mb-3 text-justify"
            dangerouslySetInnerHTML={{
              __html: EN ? data.acf?.description_en : data.acf?.description_np,
            }}
          />
          {data.acf?.file?.url && (
            <iframe
              src={data.acf?.file?.url}
              width="100%"
              height={isMobile ? "500px" : "700px"}
              title="notice"
            />
          )}
        </Container>
      )}
    </Box>
  );
};

export const Publications = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    loading: true,
    data: [],
  });
  const { loading, data } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[4],
      loading: false,
    }));
  }, [outletContext]);
  const columns = [
    {
      field: "acf",
      headerName: TitleText(EN),
      flex: 1,
      renderCell: (params) => (
        <span className="s-16">
          {EN ? params.value?.name_en : params.value?.name_np}
        </span>
      ),
    },
    {
      field: " ",
      headerName: "",
      width: isMobile ? 100 : 200,
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            navigate("/publication/" + params.row.id, { state: params.row });
          }}
          className="bg-primary-nar c-white btn_trfm p-0 s-14"
          style={{ height: "30px" }}
        >
          {ViewText(EN)}
        </Button>
      ),
    },
  ];
  const handleRowClick = (val) => {
    return;
  };
  return (
    <Box>
      <TopComp
        title={PublicationsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: PublicationsText(EN), val: "" },
        ]}
        navigate={navigate}
      />
      <Container className="mt-4">
        {MUITable(data, columns, handleRowClick, loading, "100%", "70vh")}
      </Container>
    </Box>
  );
};

export const OnePublication = () => {
  const location = useLocation();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState({
    loading: true,
    data: {},
  });
  const { loading, data } = values;
  useEffect(() => {
    if (!location.state) {
      GETAPI("publication/" + id, navigate).then((res) => {
        setValues((x) => ({ ...x, loading: false, data: res }));
      });
    } else setValues((x) => ({ ...x, data: location.state, loading: false }));
  }, [location.state, navigate, id]);
  return (
    <Box>
      <TopComp
        title={PublicationsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: PublicationsText(EN), val: "/publications" },
          { name: PublicationText(EN) + " " + id, val: "" },
        ]}
        navigate={navigate}
      />
      {loading && <CircularProgressNar />}
      {data.date && !loading && (
        <Container>
          <p className="mt-4 s-24 w3 mb-2">{NameLang(data, EN)}</p>
          <p className="c-gray">{data.date.split("T")[0]}</p>
          <p
            className="mt-3 mb-3 text-justify"
            dangerouslySetInnerHTML={{
              __html: EN ? data.acf?.description_en : data.acf?.description_np,
            }}
          />
          {data.acf?.file?.url && (
            <iframe
              src={data.acf.file.url}
              width="100%"
              height={isMobile ? "500px" : "700px"}
              title="notice"
            />
          )}
        </Container>
      )}
    </Box>
  );
};

export const Downloads = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    loading: true,
    data: [],
  });
  const { data, loading } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[5],
      loading: false,
    }));
  }, [outletContext]);
  const columns = [
    {
      field: "acf",
      headerName: TitleText(EN),
      flex: 1,
      renderCell: (params) => (
        <span className="s-16">
          {EN ? params.value?.name_en : params.value?.name_np}
        </span>
      ),
    },
    {
      field: " ",
      headerName: "",
      width: isMobile ? 90 : 200,
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            navigate("/download/" + params.row.id, { state: params.row });
          }}
          className="bg-primary-nar c-white btn_trfm p-0 s-14"
          style={{ height: "30px" }}
        >
          {ViewText(EN)}
        </Button>
      ),
    },
  ];
  const handleRowClick = (val) => {
    return;
  };
  return (
    <Box>
      <TopComp
        title={DownloadsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: DownloadsText(EN), val: "" },
        ]}
        navigate={navigate}
      />
      <Container className="mt-4">
        {MUITable(data, columns, handleRowClick, loading, "100%", "70vh")}
      </Container>
    </Box>
  );
};

export const OneDownload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const { id } = useParams();
  const [values, setValues] = useState({
    loading: true,
    data: {},
  });
  const { loading, data } = values;
  useEffect(() => {
    if (!location.state) {
      GETAPI("downloads/" + id, navigate).then((res) => {
        setValues((x) => ({ ...x, loading: false, data: res }));
      });
    } else setValues((x) => ({ ...x, data: location.state, loading: false }));
  }, [location.state, navigate, id]);
  return (
    <Box>
      <TopComp
        title={DownloadsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: DownloadsText(EN), val: "/downloads" },
          { name: DownloadsText(EN) + " " + id, val: "" },
        ]}
        navigate={navigate}
      />
      {loading && <CircularProgressNar />}
      {data.date && !loading && (
        <Container>
          <p className="mt-4 s-24 w3 mb-2">{NameLang(data, EN)}</p>
          <p className="c-gray">{data.date.split("T")[0]}</p>
          <p
            className="mt-3 mb-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: EN ? data.acf?.description_en : data.acf?.description_np,
            }}
          />
          {data.acf?.file?.url && (
            <iframe
              src={data.acf.file.url}
              width="100%"
              height={isMobile ? "500px" : "700px"}
              title="notice"
            />
          )}
        </Container>
      )}
    </Box>
  );
};
