import { Box, Container, Grid, IconButton, Modal, Paper } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { CaretLeft, CaretRight, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { GETAPI } from "../Auth";
import { GalleryText, HomeText, empty_img } from "../Components.js/Datasets";
import {
  AlertError,
  CircularProgressNar,
  NameLang,
  TopComp,
  isTextEmpty,
} from "../Components.js/common";

// const a = [
//   {
//     acf: {
//       thumbnail:
//         "https://images.unsplash.com/photo-1544568100-847a948585b9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//     },
//     title: { rendered: "Yes this" },
//   },
//   {
//     acf: {
//       thumbnail:
//         "https://images.unsplash.com/photo-1535930891776-0c2dfb7fda1a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//     },
//     title: { rendered: "second test" },
//   },
//   {
//     acf: {
//       thumbnail:
//         "https://images.unsplash.com/photo-1544568100-847a948585b9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//     },
//     title: { rendered: "our doctor" },
//   },
//   {
//     acf: {
//       thumbnail:
//         "https://images.unsplash.com/photo-1507146426996-ef05306b995a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//     },
//     title: { rendered: "doctor hi" },
//   },
//   {
//     acf: {
//       thumbnail:
//         "https://images.unsplash.com/photo-1544568100-847a948585b9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//     },
//     title: { rendered: "our doctor" },
//   },
// ];

// const b = [
//   "https://images.unsplash.com/photo-1535930891776-0c2dfb7fda1a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//   "https://images.unsplash.com/photo-1507146426996-ef05306b995a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//   "https://images.unsplash.com/photo-1549388604-817d15aa0110",
//   "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
//   "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
//   "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
//   "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
//   "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
//   "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
//   "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
//   "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
//   "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
//   "https://images.unsplash.com/photo-1544568100-847a948585b9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ",
//   "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
//   "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
// ];

export const AllGallery = () => {
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const navigate = useNavigate();
  const [loadingImage, setloadingImage] = useState(true);
  const [values, setValues] = useState({
    data: [],
  });
  const { data } = values;
  useEffect(() => {
    var all = outletContext[7];
    all.forEach((eachAll) => {
      var arr = [];
      Array(10)
        .fill("a")
        .map((element, i) => {
          var found = eachAll.acf && eachAll.acf[`image` + i];
          if (!isTextEmpty(found)) arr.push(found);
          return true;
        });
      eachAll.imageArr = arr;
    });
    setValues((x) => ({
      ...x,
      data: all,
      loading: false,
    }));
  }, [outletContext]);
  const options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <div>
      <TopComp
        title={GalleryText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: GalleryText(EN), val: "" },
        ]}
        navigate={navigate}
      />
      <Container className="mt-5">
        {loadingImage && <CircularProgressNar />}

        <Grid container spacing={2}>
          {data.map((each, i) => (
            <Grid
              key={"eachGallery" + i}
              item
              xs={12}
              md={3}
              mb={2}
              onClick={(e) => navigate("/gallery/" + each.id, { state: each })}
            >
              <Paper elevation={0} className="h-100 d-flex flex-column">
                <Box
                  className={`d-block w-100 smooth-image}`}
                  width={{ xs: "100%" }}
                  height={{ xs: "184px" }}
                  sx={{
                    backgroundImage: `url(${each?.acf?.thumbnail})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src={each?.acf?.thumbnail}
                    alt="background"
                    style={{ display: "none" }}
                    onLoad={(e) => setloadingImage(false)}
                  />
                </Box>
                <span className="d-flex justify-content-center w-100 mt-2">
                  {new Date(each.date_gmt).toLocaleDateString("en-US", options)}
                </span>
                <span className="d-flex justify-content-center w-100 w3 s-16 text-center">
                  {NameLang(each, EN)}
                </span>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

const empty_image = `${empty_img}`;

export const Gallery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const params = useParams();
  const [values, setValues] = useState({
    first: true,
    data: [],
    imgOpen: false,
    selectedImgIndex: null,
    errorPosition: "",
    errorText: "",
    loading: true,
  });
  const {
    first,
    data,
    imgOpen,
    selectedImgIndex,
    errorPosition,
    errorText,
    loading,
  } = values;

  const state = location.state;

  useEffect(() => {
    if (first) {
      if (!state)
        GETAPI(`image_gallery/${params.id}`, navigate).then((res) => {
          var images = res.acf;
          var arr = [];
          Array(10)
            .fill("a")
            .map((element, i) => {
              var found = images[`image` + i];
              if (!isTextEmpty(found)) arr.push(found);
              return true;
            });
          var setStateData = {
            data: arr,
          };
          if (arr.length === 0) {
            setStateData.loading = false;
          }
          setValues((x) => ({
            ...x,
            // data: arr,
            ...setStateData,
          }));
        });
      else {
        var images = state.acf;
        var arr = [];
        Array(10)
          .fill("a")
          .map((element, i) => {
            var found = images[`image` + i];
            if (found) arr.push(found);
            return true;
          });
        var setStateData = {
          data: arr,
        };
        if (arr.length === 0) {
          setStateData.loading = false;
        }
        setValues((x) => ({
          ...x,
          //   data: arr,
          ...setStateData,
        }));
      }
    }
    /* eslint-disable */
  }, []);

  //   keyboard actins for gallery
  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      setValues((x) => ({
        ...x,
        selectedImgIndex:
          selectedImgIndex === 0 ? data.length - 1 : selectedImgIndex - 1,
      }));
    } else if (event.keyCode === 39) {
      setValues((x) => ({
        ...x,
        selectedImgIndex:
          selectedImgIndex + 1 === data.length ? 0 : selectedImgIndex + 1,
      }));
    }
  };
  return (
    <Box onKeyDown={handleKeyDown}>
      <TopComp
        title={EN ? location.state?.acf?.name_en : location.state?.acf?.name_np}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: GalleryText(EN), val: "/gallery" },
          {
            name: EN
              ? location.state?.acf?.name_en
              : location.state?.acf?.name_np,
            val: "",
          },
        ]}
        navigate={navigate}
      />
      <Container className="mt-5">
        {loading && <CircularProgressNar />}
        <div className="grid-container">
          {data.map((each, i) => (
            <div className="grid-container-div" key={"gallery" + i}>
              <img
                className={`grid-item grid-item-${i} grid-container-img`}
                src={each}
                onClick={(e) => {
                  setValues((x) => ({
                    ...x,
                    selectedImgIndex: i,
                    imgOpen: true,
                  }));
                }}
                onLoad={(e) => setValues((x) => ({ ...x, loading: false }))}
                alt=""
              />
            </div>
          ))}
        </div>
        {!loading && data.length === 0 && (
          <Box className="d-flex flex-column p-5 align-items-center">
            <img src={empty_image} alt="" />
            <span className="mt-3">No image yet for this gallery</span>
          </Box>
        )}
        {AlertError(errorPosition, errorText, setValues)}
      </Container>
      <ImageModal
        imgOpen={imgOpen}
        allImages={data}
        selectedImgIndex={selectedImgIndex}
        setValues={setValues}
      />
    </Box>
  );
};

const popup_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 0,
};

const ImageModal = ({ imgOpen, allImages, selectedImgIndex, setValues }) => (
  <Modal
    open={imgOpen}
    onClose={(e) =>
      setValues((x) => ({ ...x, imgOpen: false, selectedImgIndex: null }))
    }
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      onClick={(e) =>
        setValues((x) => ({ ...x, imgOpen: false, selectedImgIndex: null }))
      }
      sx={popup_style}
      width={{ xs: "100vw", md: "100vw" }}
      height={{ xs: "100vh", md: "100vh" }}
      className="d-flex flex-column"
    >
      <IconButton
        onClick={(e) =>
          setValues((x) => ({ ...x, imgOpen: false, selectedImgIndex: null }))
        }
        className="bg-white ml-auto position-absolute gallery-x-icon"
        style={{ width: "30px", height: "30px" }}
      >
        <X color="#000" />
      </IconButton>
      <Box
        maxWidth={{ xs: "100vw", md: "100%" }}
        className="d-flex align-items-center align-self-center mt-auto mb-auto justify-content-between w-100 px-5"
      >
        <IconButton
          className="mr-2"
          style={{
            width: "30px",
            height: "30px",
            background: "#a7a4a4",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setValues((x) => ({
              ...x,
              selectedImgIndex:
                selectedImgIndex === 0
                  ? allImages.length - 1
                  : selectedImgIndex - 1,
            }));
          }}
        >
          <CaretLeft />
        </IconButton>
        <Box
          maxWidth={{ xs: "400px", md: "600px" }}
          maxHeight={{ xs: "80vh", md: "80vh" }}
        >
          <img
            onClick={(e) => e.stopPropagation()}
            src={allImages[selectedImgIndex]}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
        <IconButton
          className="ml-2"
          style={{
            width: "30px",
            height: "30px",
            background: "#a7a4a4",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setValues((x) => ({
              ...x,
              selectedImgIndex:
                selectedImgIndex + 1 === allImages.length
                  ? 0
                  : selectedImgIndex + 1,
            }));
          }}
        >
          <CaretRight />
        </IconButton>
      </Box>
    </Box>
  </Modal>
);

export const MasonryImageList = (image_gallery, setValues) => {
  return (
    <Box sx={{ width: "100%" }} height="auto" mt={3}>
      <ImageList variant="masonry" className="grid">
        {image_gallery.map((item, i) => (
          <ImageListItem key={item + i}>
            <img
              className="item border-0"
              src={`${item}?w=248&fit=crop&auto=format`}
              srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt=""
              loading="lazy"
              onLoad={(e) => setValues((x) => ({ ...x, loading: false }))}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
