import { Button, Container, Grid, Paper, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { ArrowRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { NameLang, TopComp } from "../Components.js/common";
import { HomeText, OurServicesText } from "../Components.js/Datasets";

export const Services = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const loadingMain = outletContext[14];
  const [values, setValues] = useState({
    services: [],
  });
  const { services } = values;
  useEffect(() => {
    var servs = outletContext[12];
    setValues((x) => ({
      ...x,
      services: servs,
      loading: false,
    }));
  }, [outletContext, location.state]);

  const handleClickService = (each) => {
    navigate("/services/view", { state: each });
  };
  return (
    <Box>
      <TopComp
        title={OurServicesText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: OurServicesText(EN), val: "" },
        ]}
        navigate={navigate}
      />
      <Container>
        {loadingMain ? (
          <Grid container spacing={2} mt={3}>
            {[1, 2, 3, 4].map((each, i) => (
              <Grid key={each.id} item xs={6} md={2.4}>
                <Paper className="our_services_paper p-0 mt-0 cursor_pointer">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height="100%"
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2} mt={2}>
            {services?.map((each, i) => {
              return (
                <Grid
                  item
                  key={each.id}
                  xs={6}
                  md={3}
                  className="cursor_pointer"
                >
                  <Paper
                    elevation={0}
                    onClick={(e) => handleClickService(each)}
                    style={{ borderRadius: "0px" }}
                  >
                    <Box
                      width={{ xs: "100%", md: "100%" }}
                      height={{ xs: "140px", md: "200px" }}
                      className="d-block smooth-image d-flex p-2"
                      sx={{
                        backgroundImage: `linear-gradient(0.51deg, rgba(0, 0, 0, 0.5) 0.44%, rgba(0, 0, 0, 0) 49.69%), url(${each?.acf?.single_page_cover_image?.url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <span className="mt-auto s-16 w3 c-white">
                        {NameLang(each, EN)}
                      </span>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export const Service = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const loadingMain = outletContext[14];
  const [values, setValues] = useState({
    first: true,
    services: [],
    selectedService: null,
    loading: true,
  });
  const { first, services, selectedService, loading } = values;
  useEffect(() => {
    if (first) {
      var servs = outletContext[12];
      setValues((x) => ({
        ...x,
        services: servs,
        selectedService: location.state,
        loading: false,
        first: false,
      }));
    }
  }, [outletContext, location.state, first, selectedService]);
  useEffect(() => {
    setValues((x) => ({ ...x, first: true }));
  }, [location.state]);
  const handleClick = (each) => {
    setValues((x) => ({ ...x, selectedService: each }));
  };
  return (
    <Box
      className={`d-block w-100 smooth-page page-${
        !loading ? "visible" : "hidden"
      }`}
      minHeight="80vh"
    >
      {loadingMain ? (
        <Container>
          <Grid container spacing={2} mt={3}>
            {[1, 2, 3, 4].map((each, i) => (
              <Grid key={each.id} item xs={6} md={2.4}>
                <Paper className="our_services_paper p-0 mt-0 cursor_pointer">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height="100%"
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : (
        <div>
          {loading || !selectedService ? null : (
            <Box>
              <TopComp
                title={NameLang(selectedService, EN)}
                paths={[
                  { name: HomeText(EN), val: "/" },
                  { name: OurServicesText(EN), val: "/services" },
                  { name: NameLang(selectedService, EN) },
                ]}
                navigate={navigate}
              />
              <Box className="mt-5">
                <Container>
                  <Box className="d-flex">
                    <Box
                      className="sticky_dep"
                      width={{ xs: "0", md: "360px" }}
                      bgcolor="#ECF4FF"
                      padding={{ xs: "", md: "32px" }}
                      maxHeight={{ xs: "", md: "calc(100vh - 140px)" }}
                      height={{ xs: "", md: "fit-content" }}
                      overflow="scroll"
                      style={{ overflowX: "hidden", scrollbarWidth: "thin" }}
                      border-radius="10px"
                    >
                      <p>{OurServicesText(EN)}</p>
                      <Box height={"auto"}>
                        {services.map((each, i) => (
                          <div key={each.id}>
                            <Button
                              className={`department_btn ${
                                selectedService.id === each.id
                                  ? "bg-secondary-nar"
                                  : "bg-primary-nar"
                              }`}
                              onClick={(e) => handleClick(each)}
                            >
                              <span>{NameLang(each, EN)}</span>
                              <ArrowRight
                                className="ml-auto c-white"
                                size={20}
                              />
                            </Button>
                          </div>
                        ))}
                      </Box>
                    </Box>
                    {selectedService && (
                      <Box
                        width={{ xs: "100%", md: "716px" }}
                        marginLeft={{ xs: "", md: "auto" }}
                      >
                        <Box
                          width={{ xs: "100%", md: "100%" }}
                          className="ml-auto"
                          height={{ xs: "auto", md: "300px" }}
                          borderRadius={"12px"}
                          sx={{
                            background: `url(${selectedService.acf?.single_page_cover_image?.url}) `,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                        {/* <img
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                          src={
                            selectedService.acf?.single_page_cover_image?.url
                          }
                          alt=""
                        /> */}
                        <p className="mt-4 c-primary s-32 w4">
                          {NameLang(selectedService, EN)}
                        </p>
                        <p
                          className="mt-3 text-justify"
                          dangerouslySetInnerHTML={{
                            __html: EN
                              ? selectedService.acf?.description_en
                              : selectedService.acf?.description_np,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Container>
              </Box>
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};
