import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { HomeText, StaffDetailText } from "../../Components.js/Datasets";
import { StaffsArr, TopComp } from "../../Components.js/common";

export const StaffDetail = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const loadingMain = outletContext[14];
  return (
    <div>
      <TopComp
        title={StaffDetailText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: StaffDetailText(EN) },
        ]}
        navigate={navigate}
      />
      <Container className="pt-4">
        {loadingMain ? (
          <Grid container spacing={2} mt={2}>
            {[1, 2, 3, 4].map((each, i) => {
              return (
                <Grid
                  item
                  key={each.id}
                  xs={6}
                  md={3}
                  height={"294px"}
                  className="cursor_pointer"
                >
                  <Skeleton
                    variant="rectangular"
                    className="h-100"
                    width={{ xs: "100%", md: "100%" }}
                  ></Skeleton>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          StaffsArr(EN, outletContext[20])
        )}
      </Container>
    </div>
  );
};
