import { Container, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { HomeText, OrgStructureText } from "../../Components.js/Datasets";
import { TopComp, isMobile } from "../../Components.js/common";
import { ImageModalZoom } from "./CitizenCharter";

export const OrgStructure = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    loadingImage: true,
    data: [],
  });
  const { data, loadingImage } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[19],
      loading: false,
    }));
  }, [outletContext, EN]);
  const [open, setOpen] = useState(false);
  return (
    <div>
      <TopComp
        title={OrgStructureText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: OrgStructureText(EN) },
        ]}
        navigate={navigate}
      />
      <Container>
        {loadingImage && (
          <Skeleton
            className="mt-4"
            width="100%"
            style={{
              borderRadius: "12px",
            }}
            height={isMobile ? "200px" : "700px"}
            animation="wave"
            variant="rounded"
          />
        )}
        <div
          className="d-flex flex-column mt-4 align-items-center"
          onClick={(e) => setOpen(true)}
        >
          <img
            id="citizen_image"
            style={{ borderRadius: "12px" }}
            className={`w-100 smooth-image bg-white image-${
              !loadingImage ? "visible" : "hidden"
            }`}
            src={data?.acf?.image}
            onLoad={(e) => setValues((x) => ({ ...x, loadingImage: false }))}
            alt=""
          />
        </div>
        {/* modal */}
        {ImageModalZoom(open, setOpen, data?.acf?.image)}
      </Container>
    </div>
  );
};
