import { Container } from "@mui/material";
import { Bug, WarningOctagon } from "phosphor-react";

export const PageNotFound = () => {
  return (
    <Container
      className="d-flex flex-column mt-auto p-5 align-items-center justify-content-center"
      sx={{ minHeight: "60vh" }}
    >
      <div className="d-flex align-items-center">
        <WarningOctagon size={100} />
        <span className="ml-3" style={{ fontSize: "100px" }}>
          404
        </span>
      </div>
      <span className="s-45 mt-4 mb-4">Page not found</span>
      <span className="s-24">
        Something went wrong. Please go back to the homepage or contact us.
      </span>
    </Container>
  );
};

export const ServerError = () => {
  return (
    <Container
      className="d-flex flex-column mt-auto p-5 align-items-center justify-content-center"
      sx={{ height: "100vh" }}
    >
      <div className="d-flex align-items-center">
        <Bug size={100} />
        <span className="ml-3" style={{ fontSize: "100px" }}>
          500
        </span>
      </div>
      <span className="s-45 mt-4 mb-4 w3">We'll be back soon</span>
      <span className="s-24">
        Something went wrong. Please go back to the homepage or contact us.
      </span>
    </Container>
  );
};
