import { LANG, mediaUrl } from '../Components.js/Datasets'
import { API } from './config'

export const POSTAPIContact = async (path, body, navigate) => {
    try {
        const response = await fetch(path, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: body
        })
        if (response.statusText === "OK") {
            return await response.json()
        }
        else navigate('/429')
    } catch (err) {
        return err
    }
}

export const POSTAPI = async (path, body) => {
    try {
        const response = await fetch(`${API}${path}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
        return await response.json()
    } catch (err) {
        return err
    }
}

export const GETAPI = async (path, navigate) => {
    try {
        const response = await fetch(`${API}${path}?acf_format=standard`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
        })
        if (response.statusText === "OK") {
            return await response.json()
        }
        else navigate('/429')
    } catch (err) {
        navigate('/429')
        return err
    }
}

export const GET_Simple = async (path, headers) => {
    return fetch(`${path}`, headers)
}

export const GET = async (path, headers) => {
    return fetch(`${API}${path}?acf_format=standard`, headers)
}

export const GET_100 = async (path, headers) => {
    return fetch(`${API}${path}?acf_format=standard&per_page=100`, headers)
}

export const GETImage = async (path) => {
    return fetch(`${mediaUrl}${path}`)
}

export const saveLanguage = (language) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('nardevi_lang', language)
    }
}

export const isLangEN = () => {
    if (typeof window == 'undefined') {
        return true
    }
    if (localStorage.getItem('nardevi_lang') == null) {
        return true
    }
    if (localStorage.getItem('nardevi_lang')) {
        return localStorage.getItem('nardevi_lang') === LANG.EN ? true : false
    }
    else {
        return true
    }
}