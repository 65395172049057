import {
    Alert, Avatar, Box, Button, CircularProgress, Container,
    Grid, IconButton,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper, Select, Skeleton, Snackbar,
    ThemeProvider,
    Tooltip,
    createTheme,
    styled
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Carousel } from "antd";
import { CaretRight, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { POSTAPIContact } from "../Auth";
import { BaseUrl } from "../Auth/config";
import {
    BookAppointmentText, EmailText,
    FullNameText,
    MessageText, PhoneText,
    SubmitText,
    fontName
} from "./Datasets";

export const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
).matches;

export const CarouAnt = (props) => {
    const { slide, EN, employee, loadingSecond, hotlines } = props
    const [loaded, setLoaded] = useState(false)
    const contentStyle = {
        height: '350px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    useEffect(() => { }, [loaded])
    return (
        <div>
            {/* carousel and cards */}
            <div className="d-flex flex-column flex-md-row justify-content-between">
                {/* carousel */}
                <Box width={{ xs: '100vw', md: '920px' }} id='aa'
                >
                    {!loaded &&
                        <Skeleton sx={{ bgcolor: 'grey.400' }}
                            variant="rectangular"
                            animation='wave'
                            width={'920px'}
                            className="slick-list"
                            height={isMobile ? '75vh' : '488px'}
                        />
                    }
                    <Carousel
                        autoplay
                        style={{ height: !loaded && '0px', visibility: !loaded && 'hidden' }}>
                        {
                            slide.map((item, i) => {
                                if (item.imageUrl)
                                    return (
                                        <Box id='aa'
                                            width={{ xs: '100vw', md: '920px' }}
                                            style={contentStyle} key={item.id} className='d-flex'
                                        >
                                            <Box position={'absolute'}
                                                className={`c-white d-flex flex-column smooth-image
                                                 image-${loaded ? 'visible' : 'hidden'}`}
                                                width={'920px'}
                                                height='100%'
                                            >
                                                <Box
                                                    className={`d-flex flex-column align-items-start
                                                pr-3 pl-3
                                                 pl-md-5 justify-content-end pb-5 pb-md-0
                                                justify-content-md-center
                                                carousel-text
                                                `}
                                                    height='100%'
                                                    width={{ xs: '100vw', md: '490px' }}>
                                                    <p className='mb-2 s-36 w3'>
                                                        {EN ? item.acf?.name_en : item.acf?.name_np}
                                                    </p>
                                                    <p className="s-16 mb-0">
                                                        {EN ? item.acf?.image_description_en : item.acf?.image_description_np}
                                                    </p>
                                                </Box>
                                            </Box>
                                            <Box
                                                className={`d-block w-100 smooth-image}`}
                                                width={{ xs: '920px', md: '920px !important' }}
                                                // width={{ xs: '100vw' }}
                                                height={{ xs: '75vh', md: '488px' }}
                                                sx={{
                                                    backgroundImage: `url(${item.imageUrl})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: isMobile ? 'cover' : 'cover',
                                                }}>
                                            </Box>
                                            <img
                                                className="d-none"
                                                src={item.imageUrl}
                                                onLoad={e => setLoaded(true)}
                                                alt="First slide"
                                            />
                                        </Box>
                                    )
                                else return null
                            })
                        }
                    </Carousel >
                </Box>
                {/* cards */}
                {(!loaded || loadingSecond) ?
                    <>
                        {
                            isMobile ?
                                <Box className='d-flex flex-md-column justify-content-between'
                                    marginTop={{ xs: '16px', md: '0' }}
                                    height={'216px'}
                                >
                                    <Box className='d-flex flex-column director-card'
                                        ml={{ xs: '16px', md: '24px' }}
                                        borderRadius={'12px'}
                                        boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                                        <Skeleton animation='wave' height={'100%'} variant="rectangular" />
                                    </Box>
                                    <Box className='d-flex flex-column director-card'
                                        ml={{ xs: '0', md: '24px' }}
                                        mr={{ xs: '16px', md: '0' }}
                                        borderRadius={'12px'}
                                        boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                                        <Skeleton animation='wave' height={'100%'} variant="rectangular" />
                                    </Box>
                                </Box>
                                :
                                <Box className='d-flex flex-md-column justify-content-between'
                                    marginTop={{ xs: '16px', md: '0' }}
                                >
                                    <Box className='d-flex flex-column director-card'
                                        ml={{ xs: '0', md: '24px' }}
                                        mr={{ xs: '16px', md: '0' }}
                                        padding={'17px 16px'} gap='12px'
                                        borderRadius={'12px'}
                                        minHeight={{ xs: '', md: '224px' }}
                                        boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                                        <Avatar className="director-card--img" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                    <Box className='d-flex flex-column director-card'
                                        ml={{ xs: '0', md: '24px' }}
                                        mr={{ xs: '16px', md: '0' }}
                                        padding={'17px 16px'} gap='12px'
                                        borderRadius={'12px'}
                                        minHeight={{ xs: '', md: '224px' }}
                                        boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                                        <Avatar className="director-card--img" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                </Box>
                        }
                    </>
                    :
                    <Box className='d-flex flex-md-column justify-content-between'
                        marginTop={{ xs: '16px', md: '0' }}
                        gap={{ xs: '', md: '2px' }}
                    >
                        <Box className='d-flex flex-column director-card'
                            ml={{ xs: '16px', md: '24px' }}
                            borderRadius={'12px'}
                            padding={'17px 16px'} gap='12px'
                            minHeight={{ xs: '', md: '224px' }}
                            boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                            <Avatar src={employee[0]?.acf?.image?.url}
                                className="director-card--img"
                                alt="" />
                            <Box gap='2px'
                                className="d-flex flex-column w-100 align-items-center"
                            >
                                <Tooltip title={NameLang(employee[0], EN)}>
                                    <span className="w3 s-14 text-center ellipsis">
                                        {NameLang(employee[0], EN)}
                                    </span>
                                </Tooltip>
                                <Tooltip title={employee[0]?.acf?.designation}>
                                    <span className="s-12 ellipsis text-center c-gray">
                                        {EN ? employee[0]?.acf?.designation_en : employee[0]?.acf?.designation_np}
                                    </span>
                                </Tooltip>
                                <Tooltip title={EN ? employee[0]?.acf?.phone_en : employee[0]?.acf?.phone_np}>
                                    <p className="s-12 ellipsis text-center mb-0">{
                                        EN ? employee[0]?.acf?.phone_en : employee[0]?.acf?.phone_np
                                    }</p>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box className='d-flex flex-column director-card mb-2'
                            ml={{ xs: '0', md: '24px' }}
                            mr={{ xs: '16px', md: '0' }}
                            padding={'17px 16px'} gap='12px'
                            borderRadius={'12px'}
                            minHeight={{ xs: '', md: '224px' }}
                            boxShadow={'0px 5px 20px rgba(82, 96, 224, 0.1)'}>
                            <Avatar src={employee[1]?.acf?.image?.url}
                                className="director-card--img"
                                alt="" />
                            <Box gap='2px'
                                className="d-flex flex-column w-100 align-items-center"
                            >
                                <Tooltip title={NameLang(employee[1], EN)}>
                                    <span className="w3 s-14 text-center ellipsis">
                                        {NameLang(employee[1], EN)}
                                    </span>
                                </Tooltip>
                                <Tooltip title={EN ? employee[1]?.acf?.designation_en : employee[1]?.acf?.designation_np}>
                                    <span className="s-12 ellipsis text-center c-gray">{EN ? employee[1]?.acf?.designation_en : employee[1]?.acf?.designation_np}</span>
                                </Tooltip>
                                <Tooltip title={EN ? employee[1]?.acf?.phone_en : employee[1]?.acf?.phone_np}>
                                    <span className="s-12 ellipsis text-center">{EN ? employee[1]?.acf?.phone_en : employee[1]?.acf?.phone_np}</span>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>}
            </div >
            {/* other info */}
            <Box className='bg-primary-nar d-flex flex-column flex-md-row justify-content-between'
                gap={{ xs: '16px', md: '4px' }}
                padding={{ xs: '16px 10px', md: '16px 6px' }}
                minHeight={'91px'}
                height={{ xs: 'auto', md: 'auto' }}>
                {
                    (hotlines?.length === 0 ? [1, 2, 3] : hotlines)?.map((each, i) => (
                        <div className="w-100 d-flex justify-content-md-center align-items-center c-white">
                            {loadingSecond ?
                                <Skeleton variant="circular" width={'40px'} height={'40px'} /> :
                                <Avatar src={each?.acf?.icon?.url} />}
                            <div className="d-flex flex-column ml-3">
                                <span className="s-20 w4">{loadingSecond ? <Skeleton variant="text" width={'300px'} /> : TitleLang(each, EN)}</span>
                                <span className="s-18">{loadingSecond ? <Skeleton variant="text" width={'300px'} /> : InfoLang(each, EN)}</span>
                            </div>
                        </div>
                    ))
                }
            </Box>
        </div>
    )
}

export const handleClickBread = (path, navigate) => {
    navigate(path)
}

export const getMuiTheme = () => createTheme({
    components: {
        MUIDataTableSelectCell: {
            styleOverrides: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: 'hidden',
                    zIndex: '5'
                },
                root: {
                    backgroundColor: "#ffffff",
                    zIndex: '1'
                }
            }
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    zIndex: '5',
                },
                stackedHeader: {
                    verticalAlign: 'middle'
                },
                stackedCommon: {
                    verticalAlign: 'middle'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "#1F2031 !important",
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 'bold',
                    fontFamily: fontName,
                    fontStyle: 'normal'
                },
                body: {
                    color: "#1F2031 !important",
                    fontSize: '16px',
                    lineHeight: '27px',
                    fontWeight: 'normal',
                    fontFamily: fontName,
                    fontStyle: 'normal'
                },
            }
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                sortAction: {
                    color: "#1F2031 !important",
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 'bold',
                    fontFamily: fontName,
                    fontStyle: 'normal'
                },
                sortActive: {
                    color: "#1F2031 !important"  // whatever you need
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root:
                {
                    '&$hover:hover':
                        { backgroundColor: '#ffefef' }
                }
            }
        }
    },
})

export const MUITable = (products, columns, handleRowClick, loading, widthVal, heightVal) => {

    const [values, setValues] = useState({
        pageSize: 10,
    })
    const { pageSize } = values

    const assignRowMUI = (data) => {
        return data
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <div style={{ height: heightVal, width: widthVal, backgroundColor: '#fff' }}>
                <DataGridNar
                    rows={assignRowMUI(products)}
                    columns={columns}
                    loading={loading}
                    getRowHeight={() => 'auto'}
                    headerHeight={70}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onPageSizeChange={(newPageSize) => setValues(x => ({ ...x, pageSize: newPageSize }))}
                    getRowId={row => row._id ?? Math.random()}
                    disableSelectionOnClick
                    onCellClick={(e) => handleRowClick(e.row)}
                />
            </div>
        </ThemeProvider>
    )
}


export const DataGridNar = styled(DataGrid)(({ theme }) => ({
    '&.MuiDataGrid-root': {
        fontFamily: fontName, fontWeight: '400',
        fontSize: '16px', lineHeight: '27px',
        color: '#2E2E2E', outline: '0',
        backgroundColor: '#fff',
        '&.MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        // padding: '0 10px'
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600', fontSize: '16px', lineHeight: '24px', color: '#6B6B6B'
    },
    '.MuiDataGrid-cell:focus': {
        outline: 'none'
    },
    '.MuiDataGrid-columnHeader:focus': {
        outline: '0'
    },
    '.MuiDataGrid-virtualScroller': {
        scrollbarWidth: 'thin'
    },
    "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important"
    },
    "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
        padding: '16px'
    },
    "& .MuiDataGrid-row": {
        maxHeight: "none !important"
    },
    '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0 8px'
    },
    "p": {
        "margin-top": "0",
        "margin-bottom": "0"
    }
}));

export const sendAllEmails = async (images) => {
    var servicesIconObj = {}
    for (let element of images) {
        var thisEl = await element.json();
        thisEl.id && (servicesIconObj[thisEl.id.toString()] = thisEl.source_url)
    }
    return servicesIconObj
};

export const AlertError = (errorPosition, errorText, setValues) => {
    var sev = ''
    if (errorPosition === 'success') sev = 'success'
    else if (errorPosition === 'apiErr') sev = 'error'
    return (
        (errorPosition === 'mainErr' || errorPosition === 'apiErr' || errorPosition === 'success') &&
        <Snackbar
            style={{ zIndex: '10000' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={errorPosition === 'mainErr' || errorPosition === 'apiErr' || errorPosition === 'success'}
            autoHideDuration={3000}
            onClose={() => setValues(val => ({ ...val, errorPosition: '', errorText: '' }))}
            onClick={() => setValues(val => ({ ...val, errorPosition: '', errorText: '' }))}
            message="I love snacks"
            key={'top+center'}>
            <Alert
                variant="filled"
                severity={sev}>
                {errorText}
            </Alert>
        </Snackbar>
    )
}

const rejectedFn = (arr, setFn, count, navigate) => {
    if (count === 5) {
        navigate("/server-error");
    }
    else {
        setFn(x => ({ ...x, errorPosition: 'apiErr', errorText: 'retrying...' }))
        asyncCall(arr, setFn, count + 1, navigate)
    }
}

export const asyncCall = async (arr, setFn, count, navigate) => {
    const p2 = new Promise((_r, rej) => setTimeout(() => rej(false), 22000)); //11000
    try {
        const result = await Promise.race([Promise.all(arr), p2]);
        var finalArr = [];
        for (let element of result) {
            if (!element.ok) {
                rejectedFn(arr, setFn, count, navigate)
            }
            else var thisEl = await element.json();
            finalArr.push(thisEl);
        }
        return finalArr;
    }
    catch (e) {
        rejectedFn(arr, setFn, count, navigate)
    }

    // try {
    //     let promise = new Promise.all(arr);
    //     var finalArr = [];
    //     for (let element of promise) {
    //         if (!element.ok) throw element;
    //         else var thisEl = await element.json();
    //         finalArr.push(thisEl);
    //     }
    //     return finalArr;
    // } catch (e) {
    //     var error = await e.json();
    //     setFn((x) => ({ ...x, errorPosition: "apiErr", errorText: error.message }));
    //     return false;
    // }
}

export const CircularProgressNar = () => (
    <Box width='100%' height='50vh' className='d-flex justify-content-center align-items-center'>
        <CircularProgress />
    </Box>
)

export const TopComp = ({ title, paths, navigate }) => (
    <Box bgcolor={"#ECF4FF"} padding={{ xs: "30px 0", md: "35px 0px" }}>
        <Container className="d-flex justify-content-between flex-column flex-md-row ">
            <Box className="w4 s-36"
                maxWidth={{ xs: '100%', md: '68%' }}
            >{title}</Box>
            <div className="d-flex align-items-center">
                {paths && paths.map((each, i) => (
                    <div key={each.name}>
                        {(i === paths.length - 1) ?
                            <Box className="c-primary cursor_pointer ellipsis"
                                // style={{ maxWidth: '226px', width: 'auto' }}
                                width={'auto'}
                                maxWidth={{ xs: '40vw', md: '226px' }}
                            >{each.name}</Box>
                            :
                            <div className="d-flex align-items-center">
                                <span onClick={e => navigate(each.val)} className="cursor_pointer">{each.name}</span>
                                <CaretRight className="ml-1 ml-md-2" /> &nbsp;
                            </div>
                        }
                    </div>
                ))}
            </div>
        </Container>
    </Box>
)

export const LabelComp = (labelName, varName, errorPosition, errorText, positionScroll) => (
    <div className='d-flex mb-1 mt-1 align-items-center w-100'>
        <span className='s-16 mr-1'>{labelName}</span>
        {ErrorsComp(varName, errorPosition, errorText, positionScroll)}
    </div>
)

export const InputCompBook = (value, name, setFn, className, placeholder, errorPosition, errorText) => {
    return (
        <input type="text" value={value} style={{ marginBottom: '10px' }}
            placeholder={errorPosition === name ? errorText : placeholder}
            className={(errorPosition === name) ? `c-error ${className}` : className}
            onChange={(e) => setFn(prev => ({ ...prev, [name]: e.target.value, errorPosition: '', errorText: '' }))} />
    )
}

export const InputCompEmailBook = (value, name, setFn, className, placeholder, errorPosition, errorText) => {
    return (
        <input type="text"
            pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
            value={value}
            style={{ marginBottom: '10px' }}
            placeholder={(errorPosition === name) ? errorText : placeholder}
            className={(errorPosition === name) ? `c-error ${className}` : className}
            onChange={(e) => setFn(prev => ({ ...prev, [name]: e.target.value, errorPosition: '', errorText: '' }))} />
    )
}

export const isTextEmpty = (text) => {
    if (text === '' || text === undefined || text === null || text === 'null') {
        return true
    }
    else return false
}

export const isEmail = (email) => {
    var pattern = "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/"
    if (email.match(pattern)) return true
    else return false
}

export const ErrorsComp = (varName, errorPosition, errorText, placeholder) => {
    return (
        <>
            {
                errorPosition === varName ?
                    <>
                        <span className='c-error ml-auto align-self-center'>{errorText}</span>
                    </>
                    :
                    placeholder
            }
        </>
    )
}

export const OutlinedInputMUI = styled(OutlinedInput)(({ theme }) => ({
    backgroundColor: '#f7f7f7',
    color: '#888888',
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "1px solid transparent",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #transparent",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #transparent",
    },
}));

const getFormattedDate = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);

    // Get the time components
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);
    const seconds = ("0" + dateObj.getSeconds()).slice(-2);

    // Format the date and time components into the desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate
}

// modal for book appointment
export const BookModal = ({ openBook, setValues, navigate, EN, departments }) => {
    const [values, setFn] = useState({
        name: '', email: '', phone: '', message: '',
        date_and_time: null,
        department: '',
        errorPosition: '', errorText: '', loading: false
    })
    const { name, email, phone, message,
        date_and_time, department,
        errorPosition, errorText, loading
    } = values
    const modalStyles = {
        position: 'absolute',
        display: "flex",
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF',
        borderColor: '#FFF',
        boxShadow: 24,
        maxHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        borderRadius: '12px',
        padding: '32px 24px'
    };
    const handleClose = () => {
        setValues(x => ({ ...x, openBook: false }))
        setFn(x => ({
            ...x, name: '', email: '', phone: '',
            date_and_time: null,
            message: '', department: ''
        }))
    }
    const validateSubmit = () => {
        if (isTextEmpty(name)) {
            setFn(x => ({ ...x, errorPosition: 'name', errorText: 'Invalid name' }))
            return false
        }
        else if (isTextEmpty(phone)) {
            setFn(x => ({ ...x, errorPosition: 'phone', errorText: 'Invalid phone' }))
            return false
        }
        else if (isTextEmpty(email)) {
            setFn(x => ({ ...x, errorPosition: 'email', errorText: 'Invalid email' }))
            return false
        }
        else if (isTextEmpty(date_and_time)) {
            setFn(x => ({ ...x, errorPosition: 'date_and_time', errorText: 'Invalid date' }))
            return false
        }
        else if (isTextEmpty(department)) {
            setFn(x => ({ ...x, errorPosition: 'department', errorText: 'Invalid department' }))
            return false
        }
        else if (isTextEmpty(message)) {
            setFn(x => ({ ...x, errorPosition: 'message', errorText: 'Invalid message' }))
            return false
        }
        else {
            setFn(x => ({ ...x, errorPosition: '', errorText: '' }))
            return true
        }
    }
    const handleSubmit = () => {
        if (validateSubmit()) {
            const send_date_time = getFormattedDate(date_and_time)
            setFn((x) => ({ ...x, loading: true }));
            var formdata = new FormData();
            formdata.append("your-name", name);
            formdata.append("your-email", email);
            formdata.append("phone", phone);
            formdata.append("department", department);
            formdata.append("date_and_time", send_date_time);
            formdata.append("your-message", message);

            var app_send = {
                acf: {
                    'your-name': name,
                    'your-email': email,
                    phone: phone,
                    department: department,
                    date_and_time: send_date_time,
                    'your-message': message,
                    type: "Appointment",
                },
            };

            POSTAPIContact(
                `${BaseUrl}/wp-json/contact-form-7/v1/contact-forms/151/feedback`,
                formdata,
                navigate
            ).then((res) => {
                if (res.status === "mail_sent") {
                    setFn((x) => ({
                        ...x,
                        loading: false,
                        name: "",
                        email: "",
                        phone: "",
                        message: "",
                        subject: "",
                        date_and_time: null,
                        department: ''
                    }));
                    setValues(x => ({
                        ...x, openBook: false,
                    }))
                    setValues(x => ({
                        ...x, errorPosition: "success",
                        errorText: "Appointment Requested",
                    }))
                }
                else {
                    setFn((x) => ({
                        ...x,
                        loading: false,
                        errorPosition: "apiErr",
                        errorText: res.message,
                    }));
                }
            });

            sendAppointmentMail(app_send);

        }
    };
    return (
        <Modal open={openBook} onClose={handleClose}>
            <Box style={modalStyles} width={{ xs: '90vw', md: '600px' }}>
                {AlertError(errorPosition, errorText, setFn)}

                <Box className='d-flex justify-content-between mb-3 align-items-center'>
                    <span className="s-24 w3 d-flex align-self-center">{BookAppointmentText(EN)}</span>
                    <IconButton onClick={handleClose} ><X size={25} /></IconButton>
                </Box>

                {InputCompBook(name, 'name', setFn, 'book_modal--input mr-2', FullNameText(EN), errorPosition, errorText)}

                <div className="d-flex flex-column flex-md-row mt-2 mb-2">
                    {InputCompBook(phone, 'phone', setFn, 'book_modal--input mr-2', PhoneText(EN), errorPosition, errorText)}
                    {InputCompEmailBook(email, 'email', setFn, 'book_modal--input ml-md-2', EmailText(EN), errorPosition, errorText)}
                </div>

                {/* datepicker */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                        label={(errorPosition === 'date_and_time') ?
                            <span className="c-error">{errorText}</span> :
                            isTextEmpty(date_and_time) ?
                                <span style={{ color: '#bbbbcb' }}>Appointment Date</span> : ''
                        }
                        className="mb-2"
                        sx={{
                            backgroundColor: '#f7f7f7',
                            border: '1px solid transparent',
                            '& .MuiOutlinedInput-root': {
                                color: '#888888',
                                '& fieldset': {
                                    border: 'none',
                                    borderColor: 'none',
                                },
                            },
                        }}
                        value={date_and_time}
                        onChange={newVal => setFn(x => ({ ...x, date_and_time: newVal, errorPosition: '', errorText: '' }))}
                    />
                </LocalizationProvider>

                {/* department select */}
                <Select
                    input={<OutlinedInputMUI />}
                    displayEmpty
                    id="select-department"
                    className="mt-2 mb-3"
                    placeholder="Select"
                    value={department}
                    renderValue={(selected) => {
                        if (errorPosition === 'department')
                            return <span className="c-error">{errorText}</span>
                        else if (isTextEmpty(department))
                            return <span style={{ color: '#bbbbcb' }}>Department</span>;
                        else return department
                    }}
                    onChange={(e) =>
                        setFn((x) => ({ ...x, department: e.target.value, errorPosition: '', errorText: '' }))
                    }
                >
                    {
                        departments?.map((each, i) => (
                            <MenuItem key={each} value={NameLang(each, EN)}>{NameLang(each, EN)}</MenuItem>
                        ))
                    }
                </Select>

                <textarea type="text" value={message}
                    placeholder={errorPosition === 'message' ? errorText : MessageText(EN)}
                    className={(errorPosition === 'message') ? 'c-error book_modal--textarea' : 'book_modal--textarea'}
                    onChange={(e) => setFn(prev => ({ ...prev, message: e.target.value, errorPosition: '' }))} />

                <Button className="primary_btn mt-1" onClick={handleSubmit}>
                    {loading ? <CircularProgress style={{ color: "#fff" }} /> : SubmitText(EN)}
                </Button>
            </Box>
        </Modal>
    )
}

export const sendAppointmentMail = async (body) => {
    fetch(`${BaseUrl}/wp-json/wp/v2/appointments/`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: "Basic Z3Vlc3Q6Vlc4TiBzY2pFIHpsbE0gQ1RHUSBZelphIGZpTFc="
        },
        body: JSON.stringify(body)
    })
}

export const NameLang = (each, EN) => {
    return EN ? each?.acf?.name_en : each?.acf?.name_np
}

export const TitleLang = (each, EN) => {
    return EN ? each?.acf?.title_en : each?.acf?.title_np
}

export const InfoLang = (each, EN) => {
    return EN ? each?.acf?.info_en : each?.acf?.info_np
}


export const getDepartmentsVal = (departments, widthMd, widthXs) => {
    var width = widthMd
    if (isMobile) width = widthXs
    var finalArr = [];
    var arr1 = [];
    const lettersPerLine = width / 8.2;
    let lines = 0;
    let currentLine = "";
    const originalDeps = [...departments];
    departments.forEach((item, ind, breakLen) => {
        if (currentLine.length + item.length > lettersPerLine) {
            currentLine = "";
            arr1 = [];
            lines++;
        }
        if (lines === 3) {
            breakLen.length = ind + 1;
        }
        currentLine += item + " ";
        arr1.push(item);
        if (!finalArr[lines]) finalArr[lines] = [];
        finalArr[lines] = arr1;
    });

    if (currentLine.length > 0) {
        lines++;
    }
    var totalSelected = finalArr[0]?.length
    if (!isMobile) totalSelected = totalSelected + finalArr[1]?.length
    var left =
        originalDeps.length - totalSelected
    departments = finalArr.slice(0, isMobile ? 1 : 2);
    return { departments: departments, left: left }
}

export const getDepartmentsValDetailed = (departments, widthMd, widthXs) => {
    var width = widthMd
    if (isMobile) width = widthXs
    var finalArr = [];
    var arr1 = [];
    const lettersPerLine = width / 8.5;
    let lines = 0;
    let currentLine = "";
    departments.forEach((item, ind, breakLen) => {
        if (currentLine.length + item.length > lettersPerLine) {
            currentLine = "";
            arr1 = [];
            lines++;
        }
        if (lines === 3) {
            breakLen.length = ind + 1;
        }
        currentLine += item + " ";
        arr1.push(item);
        if (!finalArr[lines]) finalArr[lines] = [];
        finalArr[lines] = arr1;
    });

    if (currentLine.length > 0) {
        lines++;
    }
    return { departments: finalArr, left: 0 }
}

export const DepartmentsComp = (departments, left) => (
    <Box width={"100%"} mt='auto'>
        {departments.map((eachLine, lineInd) => {
            return (
                <div className="d-flex justify-content-center">
                    {eachLine.map((element, textInd) => (
                        <>
                            <Button
                                className="c-white mb-1"
                                style={{
                                    background: "#FF8515",
                                    fontSize: "12px",
                                    padding: "2px 6px",
                                    minWidth: "0px",
                                    marginRight: "5px",
                                    borderRadius: "25px",
                                }}
                            >
                                {element}
                            </Button>
                            {(lineInd === (isMobile ? 0 : 1)) &&
                                left > 0 &&
                                textInd === eachLine.length - 1 && (
                                    <span className="c-primary w3 s-10 align-self-center">
                                        +{left}
                                    </span>
                                )}
                        </>
                    ))}
                </div>
            );
        })}
    </Box>
)

// all doctors page
export const DoctorsArr = (EN, data, handleClick) => (
    <Grid container spacing={2} mt={2}>
        {data.map((each, i) => {
            var departmentsAll = EN ? each.acf?.deps_en : each.acf?.deps_np;
            return (
                <Grid
                    item
                    key={each.id}
                    xs={6}
                    md={3}
                    className="cursor_pointer"
                >
                    <Paper
                        onClick={(e) => handleClick(each)}
                        className="our_doctors--each h-100"
                        width={{ xs: "100%", md: "100%" }}
                    >
                        <Box paddingTop={{ xs: '100%', md: '' }} className='w-100 pt-0'>
                            <Avatar
                                src={each.acf?.image}
                                alt=""
                                className="our_doctors-card--img-dep ml-auto mr-auto"
                                variant="circular"
                            />
                        </Box>
                        <Box
                            className="d-flex flex-column text-center"
                            width={{ xs: "100%", md: "100%" }}
                            gap='8px'
                            height={{ xs: 'fit-content', md: '80px' }}
                            mt={{ xs: '24px', md: '24px' }}
                        >
                            <Tooltip title={NameLang(each, EN)}>
                                <span className="w3 s-16 lh-19 text-center ellipsis w-100">
                                    {NameLang(each, EN)}
                                </span>
                            </Tooltip>
                            <Tooltip title={EN
                                ? each.acf?.designations_en
                                : each.acf?.designations_np}>
                                <span className="text-center ellipsis c-gray s-12 lh-15 w-100">
                                    {EN
                                        ? each.acf?.designations_en
                                        : each.acf?.designations_np}
                                </span>
                            </Tooltip>
                            <Tooltip title={departmentsAll.join(', ')}>
                                <span className="name_two_lines c-primary w2 s-12 text-center w-100">
                                    {departmentsAll.join(', ')}
                                </span>
                            </Tooltip>
                        </Box>
                    </Paper>
                </Grid>
            );
        })}
    </Grid >
)

// all doctors page
export const StaffsArr = (EN, data) => (
    <Grid container spacing={2} mt={2}>
        {data.map((each, i) => {
            return (
                <Grid
                    item
                    key={each.id}
                    xs={6}
                    md={3}
                    className="cursor_pointer"
                >
                    <Paper
                        className="our_doctors--each h-100"
                        width={{ xs: "100%", md: "100%" }}
                    >
                        <Box paddingTop={{ xs: '100%', md: '' }} className='w-100 pt-0'>
                            <Avatar
                                src={each.acf?.image?.url}
                                alt=""
                                className="our_doctors-card--img-dep ml-auto mr-auto"
                                variant="circular"
                            />
                        </Box>
                        <Box
                            className="d-flex flex-column text-center"
                            width={{ xs: "100%", md: "100%" }}
                            gap='8px'
                            height={{ xs: 'fit-content', md: 'fit-content' }}
                            mt={{ xs: '24px', md: '24px' }}
                        >
                            <Tooltip title={NameLang(each, EN)}>
                                <span className="w3 s-16 lh-19 text-center ellipsis w-100">
                                    {NameLang(each, EN)}
                                </span>
                            </Tooltip>
                            <Tooltip title={EN
                                ? each.acf?.designation_en
                                : each.acf?.designation_np}>
                                <span className="text-center ellipsis c-gray s-12 lh-15 w-100">
                                    {EN
                                        ? each.acf?.designation_en
                                        : each.acf?.designation_np}
                                </span>
                            </Tooltip>
                        </Box>
                    </Paper>
                </Grid>
            );
        })}
    </Grid >
)

// all doctors page skeleton
export const DoctorsArrSke = (EN, data) => (
    <>
        <Skeleton variant="text" width={'300px'} />
        <Grid container spacing={2} mt={2} mb={3} className="">
            {[1, 2, 3, 4, 5].map((each, i) => {
                return (
                    <Grid
                        item
                        key={each.id}
                        xs={6}
                        md={3}
                        className="cursor_pointer"
                    >
                        <Paper
                            sx={{ height: '250px' }}
                            className="our_doctors--each p-0"
                            width={{ xs: "100%", md: "217px" }}
                        // height={{ xs: '200px', md: '276px' }}
                        >
                            <Skeleton variant="rectangular"
                                width={'100%'} height={'100%'} />
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
        <Skeleton variant="text" width={'300px'} />
        <Grid container spacing={2} mt={2} className="">
            {[1, 2, 3].map((each, i) => {
                return (
                    <Grid
                        item
                        key={each.id}
                        xs={6}
                        md={3}
                        className="cursor_pointer"
                    >
                        <Paper
                            sx={{ height: '250px' }}
                            className="our_doctors--each p-0"
                            width={{ xs: "100%", md: "217px" }}
                        // height={{ xs: '200px', md: '276px' }}
                        >
                            <Skeleton variant="rectangular"
                                width={'100%'} height={'100%'} />
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    </>
)

// departments page doctor
export const DoctorsArrDep = (EN, data, handleClick) => (
    <Grid container spacing={2} mt={2}>
        {data?.map((each, i) => {
            var departmentsAll = EN ? each.acf?.deps_en : each.acf?.deps_np;
            return (
                <Grid
                    item
                    key={each.id}
                    xs={6}
                    md={4}
                    className="cursor_pointer"
                >
                    <Paper
                        onClick={(e) => handleClick(each)}
                        className="our_doctors--each h-100"
                        width={{ xs: "100%", md: "100%" }}
                    >
                        <Avatar
                            src={each.acf?.image}
                            alt=""
                            className="our_doctors-card--img-dep"
                            variant="circular"
                        />
                        <Box
                            className="d-flex flex-column h-100"
                            width={{ xs: "100%", md: "100%" }}
                            gap='8px'
                            height={{ xs: '', md: '80px' }}
                            mt={{ xs: '10px', md: '24px' }}
                        >
                            <Tooltip title={NameLang(each, EN)}>
                                <span className="w3 s-16 lh-19 text-center ellipsis w-100">
                                    {NameLang(each, EN)}
                                </span>
                            </Tooltip>
                            <Tooltip title={EN
                                ? each.acf?.designations_en
                                : each.acf?.designations_np}>
                                <span className="text-center ellipsis c-gray s-12 lh-15 w-100">
                                    {EN
                                        ? each.acf?.designations_en
                                        : each.acf?.designations_np}
                                </span>
                            </Tooltip>
                            <Tooltip title={departmentsAll.join(', ')}>
                                <span className="name_two_lines c-primary s-10 text-center w-100">
                                    {departmentsAll.join(', ')}
                                </span>
                            </Tooltip>
                        </Box>
                    </Paper>
                </Grid>
            );
        })}
    </Grid>
)

// our doctors part in homepage
export const DoctorsArrland = (EN, ourDoctors, navigate) => (
    <Box
        id="doctor_images"
        className="our_doctors--images"
        width={{ xs: "100%", md: "1112px" }}
    >
        {ourDoctors.map((each, i) => {
            var departmentsAll = EN ? each.acf?.deps_en : each.acf?.deps_np;
            return (
                <div
                    key={each.id}
                    className="our_doctors-card cursor_pointer"
                    onClick={(e) =>
                        navigate("doctors/doctor/" + each.id, { state: each })
                    }
                >
                    <Avatar
                        src={each.acf?.image}
                        alt=""
                        className="our_doctors-card--img"
                        variant="circular"
                    />
                    <Box
                        className="d-flex flex-column h-100"
                        width={{ xs: "100%", md: "100%" }}
                        gap='8px'
                        height={{ xs: '', md: '80px' }}
                        mt={{ xs: '10px', md: '24px' }}
                    >
                        <Tooltip title={NameLang(each, EN)}>
                            <span className="w3 s-16 lh-19 text-center ellipsis w-100">
                                {NameLang(each, EN)}
                            </span>
                        </Tooltip>
                        <Tooltip title={EN
                            ? each.acf?.designations_en
                            : each.acf?.designations_np}>
                            <span className="text-center ellipsis c-gray s-12 lh-15 w-100">
                                {EN
                                    ? each.acf?.designations_en
                                    : each.acf?.designations_np}
                            </span>
                        </Tooltip>
                        <Tooltip title={departmentsAll.join(', ')}>
                            <span className="name_two_lines c-primary s-10 text-center w-100">
                                {departmentsAll.join(', ')}
                            </span>
                        </Tooltip>
                    </Box>
                </div>
            );
        })}
    </Box>
)

// for skeleton
export const DoctorsArrlandSke = (EN, ourDoctors, navigate) => (
    <Box
        id="doctor_images"
        className="our_doctors--images d-none d-md-flex"
        width={{ xs: "100%", md: "1112px" }}
        height={{ xs: "100%", md: "324px" }}
    >
        {ourDoctors.map((each, i) => {
            return (
                <div
                    key={each.id}
                    className="our_doctors-card cursor_pointer p-0"
                >
                    <Skeleton className="mt-2" width={'100%'} variant="rectangular" height={'100%'} />
                </div>
            );
        })}
    </Box>
)

export const DepServices = (EN, data, handleClick) => (
    <Grid container spacing={2} mt={2}>
        {data !== false && data.map((each, i) => {
            return (
                <Grid
                    item
                    key={each.id}
                    xs={6}
                    md={'auto'}
                    className="cursor_pointer"
                >
                    <Paper elevation={0} onClick={e => handleClick(each)}>
                        <Box
                            width={{ xs: "100%", md: "223px" }}
                            height={{ xs: "140px", md: "159px" }}
                            className='d-block smooth-image d-flex p-2'
                            sx={{
                                backgroundImage: `linear-gradient(0.51deg, rgba(0, 0, 0, 0.5) 0.44%, rgba(0, 0, 0, 0) 49.69%), url(${each?.acf?.single_page_cover_image?.url})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        >
                            <span className="mt-auto s-16 w3 c-white">{NameLang(each, EN)}</span>
                        </Box>
                    </Paper>
                </Grid>
            );
        })}
    </Grid >
)

export const buildHierarchy = async (arr, EN) => {
    let wadu = arr.map((each) => {
        var ret = { ...each }
        return {
            ...ret,
            _id: each.id.toString(),
            parent: each.parent ? each.parent.toString() : null,
            name: NameLang(each, EN),
            products: each.products,
            value: each.id.toString(),
            label: NameLang(each, EN),
        };
    });
    const hierarchy = [];
    const reference = {};
    for (let category of wadu) {
        reference[category._id] = category;
        reference[category._id].childrenData = [];
        reference[category._id].children = [];
    }
    for (let category of wadu) {
        if (category.parent === null) {
            hierarchy.push(category);
        } else {
            if (reference[category.parent]) {
                reference[category.parent].childrenData.push(category);
                reference[category.parent].children.push(category);
            }
        }
    }
    return hierarchy;
};