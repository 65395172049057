import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Skeleton,
} from "@mui/material";
import { ArrowRight, CheckCircle } from "phosphor-react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { GETAPI } from "../Auth";
import {
  AcademicQualText,
  HomeText,
  OurDoctorsText,
} from "../Components.js/Datasets";
import {
  AlertError,
  CircularProgressNar,
  DoctorsArr,
  DoctorsArrSke,
  NameLang,
  TopComp,
  isTextEmpty,
} from "../Components.js/common";

export const OurDoctors = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const loadingMain = outletContext[14];
  const [values, setValues] = useState({
    data: [],
  });
  const { data } = values;
  useEffect(() => {
    const groupedDoctors = outletContext[2].reduce((acc, doctor) => {
      if (EN)
        doctor.acf.deps_en.forEach((department) => {
          if (!acc[department]) {
            acc[department] = [];
          }
          acc[department].push(doctor);
        });
      else
        doctor.acf.deps_np.forEach((department) => {
          if (!acc[department]) {
            acc[department] = [];
          }
          acc[department].push(doctor);
        });
      return acc;
    }, {});
    setValues((x) => ({
      ...x,
      data: groupedDoctors,
    }));
  }, [outletContext, EN]);
  const handleClick = (each) => {
    navigate("doctor/" + each.id, { state: each });
  };
  return (
    <Box>
      <TopComp
        title={OurDoctorsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: OurDoctorsText(EN), val: "/doctors" },
        ]}
        navigate={navigate}
      />
      <Container className="pt-4">
        {loadingMain ? (
          <div>
            <Grid container spacing={2} className="d-md-none">
              {[1, 2, 3, 4].map((each, i) => (
                <Grid key={each.id} item xs={6} md={2.4}>
                  <Paper className="our_services_paper p-0 cursor_pointer">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height="100%"
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {DoctorsArrSke(EN, data)}
          </div>
        ) : (
          <Box className="d-flex flex-column" gap={"24px"}>
            {Object.keys(data).map((department) => (
              <div key={department}>
                <span className="s-36 w2">{department}</span>
                {DoctorsArr(EN, data[department], handleClick)}
              </div>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export const Doctor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    first: true,
    data: {},
    loading: true,
    openBook: false,
    errorPosition: "",
    errorText: "",
  });
  const { first, data, loading, errorPosition, errorText } = values;
  useEffect(() => {
    if (first) {
      if (!location.state)
        GETAPI(`our_doctors/${id}`, navigate).then((res) => {
          var acf = res.acf;
          var arr_en = [];
          var arr_np = [];
          Array(10)
            .fill("a")
            .map((element, i) => {
              var found_en = acf[`academic_name` + i + "en"];
              var found_np = acf[`academic_name` + i + "_np"];
              if (!isTextEmpty(found_en)) arr_en.push(found_en);
              if (!isTextEmpty(found_np)) arr_np.push(found_en);
              return true;
            });
          var stateData = { ...res };
          stateData.academic_en = arr_en;
          stateData.academic_np = arr_np;
          setValues((x) => ({ ...x, data: stateData, loading: false }));
        });
      else {
        var acf = location.state.acf;
        var arr_en = [];
        var arr_np = [];
        Array(10)
          .fill("a")
          .map((element, i) => {
            var found_en = acf[`academic_name` + i + "_en"];
            var found_np = acf[`academic_name` + i + "_np"];
            if (!isTextEmpty(found_en)) arr_en.push(found_en);
            if (!isTextEmpty(found_np)) arr_np.push(found_np);
            return true;
          });
        var stateData = { ...location.state };
        stateData.academic_en = arr_en;
        stateData.academic_np = arr_np;
        setValues((x) => ({ ...x, data: stateData, loading: false }));
      }
    }
  }, [location.state, navigate, id, first, errorPosition]);
  const handleClickDepartment = (each) => {
    navigate("/departments/view", { state: each });
  };
  return (
    <Box>
      <TopComp
        title={OurDoctorsText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: OurDoctorsText(EN), val: "/doctors" },
          { name: NameLang(data, EN) },
        ]}
        navigate={navigate}
      />
      <Container className="mt-5">
        {loading ? (
          <CircularProgressNar />
        ) : (
          <Box className="d-flex flex-column flex-md-row">
            <Box
              width={{ xs: "100%", md: "450px" }}
              boxShadow="0px 5px 20px rgba(82, 96, 224, 0.1)"
              padding="24px"
              height={"max-content"}
              className="d-flex flex-column align-items-center"
            >
              <Avatar
                src={data.acf.image}
                alt=""
                variant="circular"
                style={{ width: "200px", height: "200px" }}
              />
              <p className="mt-3 s-24 w3 mb-2 text-center">
                {NameLang(data, EN)}
              </p>
              <p className="s-18 mb-3 text-center">
                {EN ? data.acf?.designations_en : data.acf?.designations_np}
              </p>
            </Box>
            {data.academic_en && data.academic_en.length > 0 && (
              <Box
                padding={{ xs: "24px 0px", md: "24px" }}
                className="d-flex flex-column flex-md-row ml-md-3 w-100"
              >
                {/* description and departments */}
                <div className="col-12 col-md-6">
                  <p className="mb-4 w4 s-24">
                    About Dr. {NameLang(data, EN).split(" ").splice(-1)}
                  </p>
                  <p
                    className="mt-3 text-justify"
                    dangerouslySetInnerHTML={{
                      __html: EN
                        ? data.acf?.description_en
                        : data.acf?.description_np,
                    }}
                  />
                  {data?.acf?.departments_detailed?.length > 0 && (
                    <p className="w3 s-24">Involved Departments</p>
                  )}
                  <Grid container spacing={2}>
                    {data?.acf?.departments_detailed.map((each, i) => (
                      <Grid item xs={12} md={12} key={each.id}>
                        <Button
                          className="d-flex align-items-center w3 w-100
                             justify-content-between c-primary py-2 px-2 cursor_pointer"
                          style={{
                            border: "1px solid #2460B9",
                            minHeight: "45px",
                          }}
                          onClick={(e) => handleClickDepartment(each)}
                        >
                          <span className="text-left">
                            {NameLang(each, EN)}
                          </span>
                          <ArrowRight weight="bold" />
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                {/* qualifications */}
                <div className="col-12 col-md-6 ml-md-2 mt-3 mt-md-0 w2 s-18">
                  {data.academic_en?.length > 0 && EN && (
                    <div className="w3 s-24 mb-3">{AcademicQualText(true)}</div>
                  )}

                  {data.academic_np?.length > 0 && !EN && (
                    <div className="w3 s-24 mb-3">{AcademicQualText(EN)}</div>
                  )}
                  {data.academic_en &&
                    data.academic_np &&
                    (EN ? data.academic_en : data.academic_np).map(
                      (each, i) => (
                        <div key={i + each} className="d-flex mb-2">
                          <CheckCircle
                            weight="fill"
                            color="#2460B9"
                            size={25}
                          />
                          <span className="ml-2">{each}</span>
                        </div>
                      )
                    )}
                </div>
              </Box>
            )}
          </Box>
        )}
      </Container>
      {AlertError(errorPosition, errorText, setValues)}
    </Box>
  );
};
