import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Divider,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  SwipeableDrawer,
  ThemeProvider,
  Toolbar,
  createTheme,
} from "@mui/material";
import { AutoComplete, Cascader } from "antd";
import {
  CaretDown,
  CaretUp,
  Globe,
  List,
  MagnifyingGlass,
  Phone,
  X,
} from "phosphor-react";
import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { GET, GET_100, GET_Simple, isLangEN, saveLanguage } from "../Auth";
import { BaseUrl } from "../Auth/config";
import {
  AboutUsText,
  BookAppointmentText,
  CitizenCharterText,
  ContactUsText,
  DepartmentText,
  DocScheduleText,
  DownloadsText,
  FollowUsText,
  GalleryText,
  GovtNepalText,
  HomeText,
  IntrocuctionText,
  LANG,
  NardeviAddrText,
  NardeviText,
  NoticeText,
  NoticesText,
  OrgStructureText,
  OurDoctorsText,
  OurServicesText,
  PublicationText,
  PublicationsText,
  ServicesText,
  fontName,
  nar_logo_img,
  nepal_flag_img,
} from "../Components.js/Datasets";
import {
  AlertError,
  BookModal,
  NameLang,
  asyncCall,
  buildHierarchy,
  isMobile,
  isTextEmpty,
} from "../Components.js/common";
import "../css/fonts.css";
import "../css/layout.css";
import "../index.css";

const theme = createTheme({
  typography: {
    fontFamily: fontName,
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "#FFF",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          outline: "none",
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          outline: "none",
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 770,
      lg: 1200,
    },
  },
});

const popup_style = {
  position: "absolute",
  maxHeight: "80%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 0,
};

const nar_logo_image = `${nar_logo_img}`;
export const Layout = () => {
  const navigate = useNavigate();
  const [openPopup, setopenPopup] = useState(true);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openLang = Boolean(anchorElLang);
  const [values, setValues] = useState({
    EN: isLangEN() ?? true,
    first: true,
    loading: true,
    loadingSecond: true,
    openBook: false,
    errorPosition: "",
    errorText: "",
    aboutUs: {},
    ourDepartments: [],
    ourDoctors: [],
    gallery: [],
    noticeBoard: [],
    publications: [],
    downloads: [],
    slider: [],
    hotlines: [],
    videos: [],
    popup: {},
    footer_links: [],
    services: [],
    tweets: [],
    footerContact: "",
    social_media: [],
    employee: [],
    citizen_charter: [],
    org_structure: [],
    staff_detail: [],
    doctors_schedule: [],
  });
  const {
    EN,
    first,
    errorPosition,
    errorText,
    openBook,
    loading,
    loadingSecond,
    aboutUs,
    ourDepartments,
    ourDoctors,
    gallery,
    noticeBoard,
    publications,
    downloads,
    slider,
    hotlines,
    videos,
    popup,
    footer_links,
    services,
    tweets,
    footerContact,
    social_media,
    employee,
    citizen_charter,
    org_structure,
    staff_detail,
    doctors_schedule,
  } = values;
  useEffect(() => {
    const apiCalls = async () => {
      setValues((x) => ({ ...x, first: false }));
      const url0 = "about_us";
      const url1 = "departments";
      const url2 = "notice";
      const url3 = "publication";
      const url4 = "downloads";
      const url5 = "image_slider";
      const url6 = "our_doctors";
      const url7 = "image_gallery";
      const url8 = "videos";
      const url9 = "footer_links";
      const url10 = "services";
      const url11 = "contact_us";
      const url_title =
        "https://nah.gov.np/backend/wp-json?_fields=name,description";
      var count = 0;
      //   const responses =
      await asyncCall(
        [
          GET(url0),
          GET_100(url1),
          GET_100(url2),
          GET_100(url3),
          GET_100(url4),
          GET_100(url5),
          GET_100(url6),
          GET_100(url7),
          GET_100(url8),
          GET_100(url9),
          GET_100(url10),
          GET(url11),
          //   GET(url12),
        ],
        setValues,
        count,
        navigate
      ).then((responses) => {
        if (responses === false) {
          navigate("server-error");
        }
        const aboutUs = responses[0]?.reverse();
        const departments = responses[1]?.reverse();
        const notices = responses[2];
        const publication = responses[3];
        const download = responses[4]?.reverse();
        const slider = responses[5]?.reverse();
        const doctors = responses[6]?.reverse();
        var image_gallery = responses[7]?.reverse();
        var videos = responses[8]?.reverse();
        var footer_links = responses[9]?.reverse();
        var services = responses[10]?.reverse();
        var contact_us = responses[11];
        //   services for cascader
        services = services.map((each) => {
          return {
            ...each,
            value: each?.id,
            label: NameLang(each, EN),
            children: [],
          };
        });
        //   get department names of doctors
        doctors.forEach((element) => {
          var depIds = element.acf?.departments;
          var filteredArr = [];
          if (depIds)
            filteredArr = departments.filter((obj) => depIds?.includes(obj.id));
          const namesArr_en = filteredArr.map((obj) => obj.acf.name_en);
          const namesArr_np = filteredArr.map((obj) => obj.acf.name_np);
          const namesStr_en = namesArr_en.join(", ");
          const namesStr_np = namesArr_np.join(", ");
          element.acf.departments_detailed = filteredArr;
          element.acf.deps_en = namesArr_en;
          element.acf.deps_joined_en = namesStr_en;
          element.acf.deps_np = namesArr_np;
          element.acf.deps_joined_np = namesStr_np;
        });
        // get doctors of department
        departments.forEach((element) => {
          var acf = element.acf.department_doctors;
          element.acf.department_doctors = [];
          acf &&
            acf.forEach((eachDoc) => {
              var doc = doctors.find((x) => x.id === eachDoc.ID);
              if (doc) {
                element.acf.department_doctors.push(doc);
              }
            });
        });
        //   get services of departments
        departments.forEach((element, i) => {
          var servicesVals = element.acf?.department_services
            ? element.acf?.department_services
            : [];
          servicesVals.forEach((depServ, ind) => {
            var found = services.find((x) => x.id === depServ.ID);
            servicesVals[ind] = found;
          });
          element.department_services = servicesVals;
        });
        //   hierarchy of departments
        var test = [...departments];
        buildHierarchy(test, EN).then((depsVal) => {
          slider.map((x) => (x.imageUrl = x.acf.image));
          setValues((x) => ({
            ...x,
            aboutUs: aboutUs[aboutUs.length - 1].acf,
            ourDepartments: depsVal,
            ourDoctors: doctors,
            gallery: image_gallery,
            noticeBoard: notices,
            publications: publication,
            downloads: download,
            slider: slider,
            videos: videos,
            // popup: pop_up,
            footer_links: footer_links,
            first: false,
            loading: false,
            services: services,
            footerContact: contact_us[contact_us.length - 1].acf,
          }));
        });
      });
      //   for employee, title, description and tweets
      await asyncCall(
        [
          fetch(`${BaseUrl}/wp-json/myplugin/v1/twitter-feed`),
          GET_Simple(url_title),
          GET("employee"),
          GET("pop-up"),
          GET("hotlines"),
          GET("social_media"),
          GET("citizen_charter"),
          GET("org_structure"),
          GET("doc_schedule"),
          GET("our_staff"),
        ],
        setValues,
        count,
        navigate
      ).then((responses) => {
        if (responses === false) {
          return;
        }
        var tweets = responses[0];
        var tab_title = responses[1];
        var employeeData = responses[2].reverse();
        var pop_up = responses[3];
        var hotlines = responses[4];
        var social_media = responses[5];
        var citizen_charter_data = responses[6];
        var org_structure_data = responses[7];
        var doc_schedule_data = responses[8];
        var staff_detail_data = responses[9]?.reverse();
        if (employeeData.length > 2) {
          employeeData = employeeData.slice(0, 2);
        }
        document.title = tab_title?.name;
        const el = document.querySelector("meta[name='description']");
        el.setAttribute("content", tab_title?.description);
        setValues((x) => ({
          ...x,
          tweets: tweets,
          employee: employeeData,
          popup: pop_up,
          hotlines: hotlines,
          social_media: social_media,
          citizen_charter:
            citizen_charter_data?.[citizen_charter_data.length - 1],
          org_structure: org_structure_data?.[citizen_charter_data.length - 1],
          doc_schedule: doc_schedule_data,
          staff_detail: staff_detail_data,
          doctors_schedule:
            doc_schedule_data?.[citizen_charter_data.length - 1],
          loadingSecond: false,
        }));
      });
    };
    if (first) {
      apiCalls();
    }
  }, [first, navigate, EN]);

  const handleClickLang = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  //   close lang
  const handleClose = () => {
    setAnchorElLang(null);
  };
  const handleDepClick = (clickedDep) => {
    handleClose();
    navigate("/departments/view", { state: clickedDep });
  };
  const handleClickNav = (txt, e) => {
    if (e) {
    } else navigate(txt);
  };
  const renderTitle = (title) => <span>{title}</span>;
  const renderItem = (title, item) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
      </div>
    ),
    item: item,
  });
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleSearch = (value) => {
    setSearchText(value);
    if (isTextEmpty(value.replace(/ /g, ""))) setFilteredOptions([]);
    const filteredDoc = ourDoctors.filter((option) =>
      option.acf?.name_en.toLowerCase().includes(value.toLowerCase())
    );
    const filteredDep = ourDepartments.filter((option) =>
      option.acf?.name_en.toLowerCase().includes(value.toLowerCase())
    );
    const filteredServices = services.filter((option) =>
      option.acf?.name_en.toLowerCase().includes(value.toLowerCase())
    );
    var docArr = [];
    filteredDoc.forEach((element) => {
      docArr.push(renderItem(NameLang(element, EN), element));
    });
    var depArr = [];
    filteredDep.forEach((element) => {
      depArr.push(renderItem(NameLang(element, EN), element));
    });
    var serArr = [];
    filteredServices.forEach((element) => {
      serArr.push(renderItem(NameLang(element, EN), element));
    });
    var optionsArr = [];
    if (filteredDoc.length > 0) {
      optionsArr = [
        {
          label: renderTitle(OurDoctorsText(EN)),
          options: docArr,
        },
      ];
    }
    if (filteredDep.length > 0) {
      optionsArr.push({
        label: renderTitle(DepartmentText(EN)),
        options: depArr,
      });
    }
    if (filteredServices.length > 0) {
      optionsArr.push({
        label: renderTitle(ServicesText(EN)),
        options: serArr,
      });
    }
    setFilteredOptions(optionsArr);
  };
  //   selected item from search
  const handleSelect = (item, e) => {
    setSearchText("");
    var searchInput = document.getElementById("searchrightmd");
    var searchInputXs = document.getElementById("searchright");
    if (searchInput) searchInput.blur();
    if (searchInputXs) searchInputXs.blur();
    setFilteredOptions([]);
    if (e.item.type === "departments") {
      navigate(`departments/view`, { state: e.item });
    } else if (e.item.type === "our_doctors") {
      navigate(`doctors/doctor/${e.item.id}`, { state: e.item });
    } else if (e.item.type === "services") {
      navigate(`services/view`, { state: e.item });
    }
  };
  const handleChangeLang = (e, val) => {
    var isEN = val === LANG.EN;
    handleClose();
    saveLanguage(val);
    var changeServices = services.map((each) => {
      return {
        ...each,
        value: each?.id,
        label: NameLang(each, isEN),
        children: [],
      };
    });
    var changeDeps = ourDepartments.map((each) => {
      var chil = each.children;
      chil = chil?.map((element) => {
        return {
          ...element,
          value: element?.id,
          label: NameLang(element, isEN),
        };
      });
      return {
        ...each,
        value: each?.id,
        label: NameLang(each, isEN),
        children: chil,
      };
    });
    setValues((x) => ({
      ...x,
      services: changeServices,
      ourDepartments: changeDeps,
      EN: isEN,
    }));
  };
  const onSelectSubnav = (selected, i) => {
    var last = i.slice(-1);
    last = last[0] ?? null;
    if (last) handleDepClick(last);
  };
  const onSelectService = (selected, i) => {
    const clickedSer = i[0] ?? null;
    clickedSer &&
      navigate("/services/view", { state: clickedSer, replace: true });
  };
  const aboutUsNav = [
    { value: "about-us", label: EN ? "Introduction" : "परिचय" },
    {
      value: "citizen-charter",
      label: EN ? "Citizen Charter" : "नागरिक वडापत्र",
    },
    {
      value: "org-structure",
      label: EN ? "Organizational Structure" : "संगठनिक संरचना",
    },
    {
      value: "doc-schedule",
      label: EN ? "Doctors Schedule" : "डाक्टरको तालिका",
    },
    { value: "staff-detail", label: EN ? "Staff Detail" : "कर्मचारी विवरण" },
    { value: "downloads", label: DownloadsText(EN) },
    { value: "publications", label: PublicationText(EN) },
  ];
  const onSelectAboutUs = (selected, i) => {
    !isTextEmpty(i[0]?.value) && navigate(i[0].value);
  };
  const displayRender = (label) => <span>{label}</span>;
  function dropdownRender(menu) {
    return <Box minWidth="200px">{menu}</Box>;
  }
  return (
    <ThemeProvider theme={theme}>
      <div id="scroll_here_id" />
      <div className="d-flex flex-column">
        {/* top */}
        <Box
          bgcolor="#2460B9"
          height="30px"
          color="#fff"
          zIndex={"9999"}
          className="d-flex align-items-center justify-content-center"
        >
          <Box
            width={{ xs: "90%", md: "90vw" }}
            className="d-flex align-items-center"
          >
            <div>
              <Phone weight="fill" color="#fff" />
              <span className="ml-2">(+977)-1-4256883</span>
            </div>

            <div className="d-flex align-items-center ml-auto c-white">
              <Button
                style={{ height: "30px" }}
                className="p-0 c-white"
                id="basic-button"
                aria-controls={openLang ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLang ? "true" : undefined}
                onClick={handleClickLang}
              >
                <Globe className="mr-1" />
                <span className="mr-2">{EN ? LANG.EN : LANG.NP}</span>
                {openLang ? <CaretUp /> : <CaretDown />}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElLang}
                open={openLang}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {["EN", "NP"].map((each, i) => (
                  <MenuItem
                    key={each}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                    onClick={(e) => handleChangeLang(e, each)}
                  >
                    {each}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
        </Box>
        {/* flag */}
        {!isMobile && (
          <Box
            bgcolor="#fff"
            height="120px"
            // paddingY={"12px"}
            color="#fff"
            zIndex={"9999"}
            className="d-flex align-items-center justify-content-center"
            borderBottom={"1px solid #DFDFDF"}
          >
            <Box
              width={{ xs: "90%", md: "90vw" }}
              className="d-flex align-items-center justify-content-between"
            >
              <img
                src={nar_logo_image}
                className="cursor_pointer"
                style={{
                  width: "84px",
                  height: "100%",
                }}
                onClick={(e) => navigate("/")}
                alt="logo"
              />

              {/* center */}
              <div
                className="d-flex flex-column text-center w3 cursor_pointer"
                onClick={(e) => navigate("/")}
              >
                <span className="s-20 c-primary mb-0">{GovtNepalText(EN)}</span>
                <h1 className="c-secondary mt-0 s-28 w3">{NardeviText(EN)}</h1>
                <span className="c-text s-16">{NardeviAddrText(EN)}</span>
              </div>

              {/* flag */}
              <img
                className="cursor_pointer"
                src={nepal_flag_img}
                onClick={(e) => navigate("/")}
                style={{
                  width: "52px",
                  height: "100%",
                }}
                alt="logo"
              />
            </Box>
          </Box>
        )}
        {/* navs appbar */}
        <Box className="sticky">
          <Box style={{ backgroundColor: "#fff" }} className="">
            {/* md toolbar */}
            <Toolbar
              style={{ width: "90vw" }}
              className="d-none d-md-flex ml-auto mr-auto"
            >
              {/* navs */}
              <Box className="ml-auto d-flex" gap={"12px"}>
                {/* home */}
                <Button className="nav_btn" onClick={(e) => handleClickNav("")}>
                  {HomeText(EN)}
                </Button>
                {/* about us */}
                {/* <Button
                  className="nav_btn"
                  onClick={(e) => handleClickNav("about-us")}
                >
                  {AboutUsText(EN)}
                </Button> */}
                <Cascader
                  options={aboutUsNav}
                  allowClear={false}
                  bordered={false}
                  displayRender={(e) => displayRender(AboutUsText(EN))}
                  style={{ width: "max-content", outline: 0, border: "none" }}
                  dropdownRender={dropdownRender}
                  size="large"
                  dropdownPrefixCls="aa"
                  expandTrigger="hover"
                  changeOnSelect={false}
                  onChange={onSelectAboutUs}
                  placeholder=""
                  value={AboutUsText(EN)}
                />
                {/* notices */}
                <Button
                  className="nav_btn"
                  onClick={(e) => handleClickNav("notices")}
                >
                  {NoticesText(EN)}
                </Button>
                {/* departments */}
                <Cascader
                  options={ourDepartments}
                  allowClear={false}
                  bordered={false}
                  displayRender={(e) => displayRender(DepartmentText(EN))}
                  style={{ width: "max-content", outline: 0, border: "none" }}
                  dropdownRender={dropdownRender}
                  size="large"
                  dropdownPrefixCls="aa"
                  expandTrigger="hover"
                  changeOnSelect
                  onChange={onSelectSubnav}
                  placeholder=""
                  value={DepartmentText(EN)}
                />
                {/* services */}
                <Cascader
                  options={services}
                  allowClear={false}
                  bordered={false}
                  displayRender={(e) => displayRender(ServicesText(EN))}
                  style={{ width: "max-content", outline: 0, border: "none" }}
                  dropdownRender={dropdownRender}
                  size="large"
                  dropdownPrefixCls="aa"
                  expandTrigger="hover"
                  changeOnSelect={false}
                  onChange={onSelectService}
                  placeholder=""
                  value={OurServicesText(EN)}
                />
                {/* our doctors */}
                <Button
                  className="nav_btn"
                  onClick={(e) => handleClickNav("doctors")}
                >
                  {OurDoctorsText(EN)}
                </Button>
                {/* contact us */}
                <Button
                  className="nav_btn"
                  onClick={(e) => handleClickNav("contact-us")}
                >
                  {ContactUsText(EN)}
                </Button>
                {/* gallery */}
                <Button
                  className="nav_btn"
                  onClick={(e) => handleClickNav("gallery")}
                >
                  {GalleryText(EN)}
                </Button>
                {/* book appointment */}
                <Button
                  className="bg-primary-nar c-white ml-3"
                  onClick={(e) => setValues((x) => ({ ...x, openBook: true }))}
                >
                  {BookAppointmentText(EN)}
                </Button>
              </Box>

              <AutoComplete
                open={searchText !== ""}
                className="ml-auto"
                dropdownMatchSelectWidth={300}
                style={{ width: "80px" }}
                value={searchText}
                listHeight="400px"
                onChange={handleSearch}
                onSearch={handleSearch}
                onSelect={handleSelect}
                options={filteredOptions}
                notFoundContent={
                  <Box
                    className="d-flex justify-content-center align-items-center"
                    height={"100px"}
                  >
                    <span>0 results for {searchText}</span>
                  </Box>
                }
              >
                <div
                  className={`search-container ml-auto ${
                    !isTextEmpty(searchText) ? "search-container--shadow" : ""
                  }`}
                >
                  <form action="/search" method="get">
                    <input
                      className="search expandright"
                      id="searchrightmd"
                      autoComplete="off"
                      type="search"
                      name="q"
                      placeholder="Search"
                      onChange={(e) => null}
                      value={searchText}
                    />

                    <label
                      className="button searchbutton"
                      htmlFor="searchrightmd"
                    >
                      <MagnifyingGlass />
                    </label>
                  </form>
                </div>
              </AutoComplete>
            </Toolbar>
            {/* xs toolbar */}
            <Toolbar className="d-flex d-md-none justify-content-between">
              <IconButton
                className="d-flex"
                onClick={(e) => setDrawerOpen(true)}
              >
                <List />
              </IconButton>
              <Box className="d-flex align-items-center">
                <img
                  src={nar_logo_image}
                  className="cursor_pointer layout-logo-xs-1"
                  onClick={(e) => navigate("/")}
                  alt="logo1"
                />
                <div
                  className="d-flex flex-column text-center w3 ml-2 cursor_pointer"
                  onClick={(e) => navigate("/")}
                >
                  <span className="s-12 c-primary">{GovtNepalText(EN)}</span>
                  <span className="c-secondary s-14 w3">{NardeviText(EN)}</span>
                  <span className="c-text s-10">{NardeviAddrText(EN)}</span>
                </div>
              </Box>
              <ClickAwayListener
                onClickAway={(e) => {
                  setSearchText("");
                  setFilteredOptions([]);
                }}
              >
                <AutoComplete
                  open={searchText !== ""}
                  dropdownMatchSelectWidth={300}
                  style={{ width: "70px", height: "100%" }}
                  value={searchText}
                  onChange={handleSearch}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  options={filteredOptions}
                  notFoundContent={
                    <Box
                      className="d-flex justify-content-center align-items-center"
                      height={"100px"}
                    >
                      <span>0 results for {searchText}</span>
                    </Box>
                  }
                >
                  <div className="search-container">
                    <form action="/search" method="get">
                      <input
                        className="search expandright"
                        id="searchright"
                        type="search"
                        name="q"
                        placeholder="Search"
                        onChange={(e) => null}
                        value={searchText}
                      />

                      <label
                        className="button searchbutton"
                        htmlFor="searchright"
                      >
                        <MagnifyingGlass />
                      </label>
                    </form>
                  </div>
                </AutoComplete>
              </ClickAwayListener>
            </Toolbar>
          </Box>
          {/* notice flow */}
          <Box
            className="d-flex justify-content-center c-white"
            bgcolor={"#CC2A34"}
          >
            <Container>
              <Box width={{ xs: "90vw", height: "33px" }} className="d-flex">
                <div
                  className="bg-primary-nar s-14 h-100 d-flex align-items-center px-2"
                  style={{ width: "fit-content" }}
                >
                  <span>{NoticeText(EN)}</span>
                </div>
                <div className="ticker-wrapper-h w-100 s-12">
                  <ul className="news-ticker-h ">
                    {noticeBoard &&
                      noticeBoard.map((each, i) => (
                        <li
                          className="cursor_pointer"
                          key={each.id}
                          onClick={(e) =>
                            navigate("/notice/" + each.id, {
                              state: each,
                            })
                          }
                        >
                          {NameLang(each, EN)}
                        </li>
                      ))}
                  </ul>
                </div>
              </Box>
            </Container>
          </Box>
        </Box>
        {/* outlet */}
        <Box
          style={{ padding: "0px", minHeight: "calc(100vh - 442px)" }}
          className="mb-5"
        >
          <div className="mainBody">
            <Outlet
              context={[
                aboutUs, //0
                ourDepartments, //1
                ourDoctors, //2
                noticeBoard, //3
                publications, //4
                downloads, //5
                slider, //6
                gallery, //7
                videos, //8
                popup, //9
                setValues, //10
                EN, //11
                services, //12
                tweets, //13
                loading, //14
                employee, //15
                loadingSecond, //16
                hotlines, //17
                citizen_charter, //18
                org_structure, //19
                staff_detail, //20
                doctors_schedule, //21
              ]}
            />
          </div>
        </Box>
        {/* footer */}
        <Box className="bg-dark-nar c-white">
          <Container>
            <Box
              padding={"32px 0"}
              className="d-flex flex-column flex-md-row justify-content-between"
            >
              {/* left */}
              <Box width={{ xs: "100%", md: "340px" }}>
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <img
                    src={nar_logo_image}
                    alt=""
                    style={{ width: "50px", height: "44px" }}
                  />
                  <div
                    className="d-flex flex-column text-center w3 ml-2 cursor_pointer ml-3"
                    onClick={(e) => navigate("/")}
                  >
                    <span className="s-14 c-primary">{GovtNepalText(EN)}</span>
                    <span className="c-secondary s-18 w3">
                      {NardeviText(EN)}
                    </span>
                    <span className="c-text s-12 c-white">
                      {NardeviAddrText(EN)}
                    </span>
                  </div>
                </div>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={"200px"}
                    className="mt-3"
                  />
                ) : (
                  <p
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                      fontSize: "10pt",
                    }}
                    className="mt-3 text-justify"
                    dangerouslySetInnerHTML={{
                      __html: EN
                        ? aboutUs?.home_page_description_en
                        : aboutUs?.home_page_description_np,
                    }}
                  />
                )}
              </Box>
              <Divider className="d-md-none bg-white mt-2 mb-3" />
              {/* mid */}
              <Box
                width={{ xs: "100%", md: "300px" }}
                className="d-flex flex-column align-items-center align-items-md-start"
              >
                {loading ? (
                  <div className="w-100 d-flex flex-column">
                    <Skeleton variant="rectangular" width={"100%"} />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-2"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-2"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-2"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-2"
                    />
                  </div>
                ) : (
                  <p
                    className="s-12 footer_text"
                    dangerouslySetInnerHTML={{
                      __html: EN
                        ? footerContact?.description_en
                        : footerContact?.description_np,
                    }}
                  />
                )}
              </Box>
              {/* right */}
              <Divider className="d-md-none bg-white mt-3 mb-3" />
              <Box
                width={{ xs: "100%", md: "262px" }}
                className="d-flex flex-column align-items-center align-items-md-start"
              >
                <span className="footer_text w3" style={{ fontSize: "10pt" }}>
                  {EN ? "Related Links" : "सम्बन्धित लिंकहरू"}
                </span>
                {loading ? (
                  <div className="d-flex flex-column w-100 mt-3">
                    <Skeleton variant="rectangular" width={"100%"} />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-3"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-3"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-3"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      className="mt-3"
                    />
                  </div>
                ) : (
                  <>
                    {footer_links.map((each, i) => (
                      <Link
                        key={each.id}
                        target={"_blank"}
                        to={each?.acf?.url}
                        style={{ fontSize: "10pt" }}
                        className="footer_text c-white"
                      >
                        {NameLang(each, EN)}
                      </Link>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
        <Box className="bg-white" height={"auto"} py={"6px"}>
          <Container className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center justify-content-lg-between w-100 bg-white">
            <span>© Copyright 2023. All Rights Reserved.</span>
            <div className="d-flex align-items-center">
              <span className="mr-3 s-18">{FollowUsText(EN)}</span>
              {social_media?.map((each, i) => (
                <div
                  key={"social_media_" + i}
                  className="mr-2 social_media_img"
                  onClick={(e) => window.open(each?.acf?.link, "_blank")}
                >
                  <img
                    src={each?.acf?.icon?.url}
                    width={"24px"}
                    height={"auto"}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </Container>
        </Box>
        {/* notice popup on load */}
        {popup.length > 0 && (
          <Modal
            open={openPopup}
            onClose={(e) => setopenPopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={popup_style}
              width={{ xs: "85vw", md: "450px" }}
              className="d-flex flex-column"
            >
              <IconButton
                onClick={(e) => setopenPopup(false)}
                className="bg-white ml-auto mb-3"
                style={{ width: "fit-content" }}
              >
                <X color="#000" />
              </IconButton>
              <Box
                maxWidth={{ xs: "80vw", md: "400px" }}
                className="d-flex flex-column"
              >
                <img
                  src={popup[0] && popup[0].acf && popup[0].acf.image}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
          </Modal>
        )}
      </div>
      {/* for mobile */}
      <SwipeableTemporaryDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        navigate={navigate}
        setValues={setValues}
        EN={EN}
      />
      {AlertError(errorPosition, errorText, setValues)}
      {/* book appointment */}
      <BookModal
        openBook={openBook}
        setValues={setValues}
        EN={EN}
        departments={ourDepartments}
      />
    </ThemeProvider>
  );
};

export default function SwipeableTemporaryDrawer({
  drawerOpen,
  setDrawerOpen,
  navigate,
  setValues,
  EN,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleClickNav = (txt) => {
    toggleDrawer(false);
    navigate(txt);
  };
  const navs = [
    { title: HomeText(EN), nav: "/" },
    {
      title: AboutUsText(EN),
      //   nav: "/about-us",
      subs: [
        { title: IntrocuctionText(EN), nav: "/about-us" },
        { title: CitizenCharterText(EN), nav: "/citizen-charter" },
        { title: OrgStructureText(EN), nav: "/org-structure" },
        { title: DocScheduleText(EN), nav: "/doc-schedule" },
        { title: DownloadsText(EN), nav: "/downloads" },
        { title: PublicationsText(EN), nav: "/publications" },
      ],
    },
    { title: NoticesText(EN), nav: "/notices" },
    { title: DepartmentText(EN), nav: "/departments", sub: true },
    { title: ServicesText(EN), nav: "/services", sub: true },
    { title: OurDoctorsText(EN), nav: "/doctors" },
    { title: ContactUsText(EN), nav: "/contact-us" },
    { title: GalleryText(EN), nav: "/gallery" },
  ];
  const list = () => (
    <Box
      sx={{ width: "80vw" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        className="d-flex justify-content-center align-items-center"
        padding="16px"
      >
        <img
          src={nar_logo_image}
          className="cursor_pointer"
          style={{
            width: "70px",
            height: "100%",
          }}
          onClick={(e) => navigate("/")}
          alt="logo"
        />

        <div
          className="d-flex flex-column text-center w3 ml-2 cursor_pointer"
          onClick={(e) => navigate("/")}
        >
          <span className="s-12 c-primary">{GovtNepalText(EN)}</span>
          <span className="c-secondary s-16 w3">{NardeviText(EN)}</span>
          <span className="c-text s-12">{NardeviAddrText(EN)}</span>
        </div>
      </Box>
      <Divider />
      <Box className="d-flex flex-column cursor_pointer" width={"100%"}>
        {navs.map((each, i) => {
          return (
            <ListItem
              key={each.title}
              className={
                !each.subs && window.location.pathname === each.nav
                  ? "d-flex flex-column bg-primary-nar c-white s-16 align-items-start"
                  : "s-16 mt-2 d-flex flex-column align-items-start"
              }
              onClick={(e) => !each.subs && handleClickNav(each.nav)}
            >
              <span>{each.title}</span>
              <div className="d-flex flex-column mt-2 ml-3">
                {each.subs?.length > 0 &&
                  each.subs?.map((eachSub, ind) => (
                    <ListItem
                      key={eachSub.title + ind}
                      className={
                        window.location.pathname === eachSub.nav
                          ? "bg-primary-nar c-white s-16 rounded"
                          : "s-16 mt-2 rounded"
                      }
                      onClick={(e) => handleClickNav(eachSub.nav)}
                    >
                      <span>{eachSub.title}</span>
                    </ListItem>
                  ))}
              </div>
            </ListItem>
          );
        })}
        <Button
          className="bg-primary-nar c-white ml-2 mr-2 mt-3 mb-5"
          onClick={(e) => setValues((x) => ({ ...x, openBook: true }))}
        >
          {BookAppointmentText(EN)}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <SwipeableDrawer
        anchor={"left"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </Fragment>
  );
}
