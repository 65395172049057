import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { ArrowRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  DepServices,
  DoctorsArrDep,
  NameLang,
  TopComp,
} from "../Components.js/common";
import {
  DepartmentText,
  HomeText,
  OurDepartmentsText,
  OurDoctorsText,
  OurServicesText,
} from "../Components.js/Datasets";

// for mobile only
export const Departments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const loading = outletContext[14];
  const [values, setValues] = useState({
    departments: [],
  });
  const { departments } = values;
  useEffect(() => {
    var deps = outletContext[1];
    setValues((x) => ({
      ...x,
      departments: deps,
      selectedDepartment: location.state,
      loading: false,
    }));
  }, [outletContext, location.state]);
  const handleDepClick = (clickedDep) => {
    navigate("/departments/view", { state: clickedDep });
  };
  return (
    <Box>
      <TopComp
        title={DepartmentText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: DepartmentText(EN), val: "/departments" },
        ]}
        navigate={navigate}
      />

      {/* loading */}
      {loading && (
        <Container>
          <Grid container spacing={2} mt={3}>
            {[1, 2, 3, 4].map((each, i) => (
              <Grid
                key={each.id}
                item
                xs={6}
                md={2.4}
                onClick={(e) => handleDepClick(each)}
              >
                <Paper className="our_services_paper p-0 mt-0 cursor_pointer">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height="100%"
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}

      <Container>
        <Grid container spacing={2} mt={5} mb={5}>
          {departments.map((each, i) => (
            <Grid
              key={"dep" + each.id}
              item
              xs={6}
              md={2.4}
              onClick={(e) => handleDepClick(each)}
            >
              <Paper className="our_services_paper justify-content-start cursor_pointer">
                <Avatar
                  src={each.acf?.icon.url}
                  alt=""
                  variant="square"
                  style={{ width: "75px", height: "75px" }}
                />
                <Tooltip title={NameLang(each, EN)}>
                  <span className="w3 s-16 mt-2 text-center name_two_lines h-100">
                    {NameLang(each, EN)}
                  </span>
                </Tooltip>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export const Department = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    first: true,
    departments: [],
    selectedDepartment: null,
    loading: true,
  });
  const { first, departments, selectedDepartment, loading } = values;
  useEffect(() => {
    if (first) {
      var deps = outletContext[1];
      setValues((x) => ({
        ...x,
        departments: deps,
        selectedDepartment: location.state,
        loading: false,
        first: false,
      }));
    }
  }, [outletContext, location.state, first, selectedDepartment]);
  useEffect(() => {
    setValues((x) => ({ ...x, first: true }));
  }, [location.state]);
  const handleClick = (each) => {
    setValues((x) => ({ ...x, selectedDepartment: each }));
  };
  const handleClickDoctor = (each) => {
    navigate("/doctors/doctor/" + each.id, { state: each });
  };
  const handleClickService = (each) => {
    navigate("/services/view", { state: each });
  };
  //   const DepartmentsLeftShowChild = (deps) => (
  //     <ul className="dep_ul">
  //       {deps.map((each, i) => (
  //         <li className={each.parent && "dep_li"}>
  //           <div className="dep_div c-primary s-16">
  //             <span onClick={(e) => handleClick(each)}>{NameLang(each, EN)}</span>
  //           </div>
  //           {DepartmentsLeftShowChild(each.childrenData)}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  const DepartmentsLeftShow = (deps) => (
    <ul className="dep_ul">
      {deps.map((each, i) => (
        <li key={"left" + each.id} className={each.parent && "dep_li"}>
          <div className="dep_div">
            <Button
              className={`department_btn ${
                selectedDepartment.id === each.id
                  ? "bg-secondary-nar"
                  : "bg-primary-nar"
              }`}
              onClick={(e) => handleClick(each)}
            >
              <span className="name_two_lines">{NameLang(each, EN)}</span>
              <ArrowRight
                className="ml-auto c-white col-2 p-0"
                size={18}
                weight="bold"
              />
            </Button>
            {/* {each.childrenData?.length > 0 && (
              <div className="mt-2">
                {DepartmentsLeftShowChild(each.childrenData)}
              </div>
            )} */}
          </div>
        </li>
      ))}
    </ul>
  );
  return (
    <Box
      className={`d-block w-100 smooth-page page-${
        !loading ? "visible" : "hidden"
      }`}
      minHeight="80vh"
    >
      {loading || !selectedDepartment ? null : (
        <Box>
          <TopComp
            title={NameLang(selectedDepartment, EN)}
            paths={[
              { name: HomeText(EN), val: "/" },
              { name: DepartmentText(EN), val: "/departments" },
              { name: NameLang(selectedDepartment, EN) },
            ]}
            navigate={navigate}
          />
          <Container>
            <Box className="mt-5">
              <Box className="d-flex">
                {/* left */}
                <Box
                  className="sticky_dep"
                  width={{ xs: "0", md: "360px" }}
                  bgcolor="#ECF4FF"
                  padding={{ xs: "", md: "32px" }}
                  height={{ xs: "", md: "calc(100vh - 140px)" }}
                  overflow="scroll"
                  style={{ overflowX: "hidden", scrollbarWidth: "thin" }}
                  borderRadius="10px"
                >
                  <p className="mb-1">{OurDepartmentsText(EN)}</p>
                  <Box height={"auto"}>{DepartmentsLeftShow(departments)}</Box>
                </Box>
                {/* right */}
                {selectedDepartment && (
                  <Box
                    width={{ xs: "100%", md: "716px" }}
                    marginLeft={{ xs: "", md: "auto" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "12px",
                      }}
                      src={selectedDepartment.acf?.single_page_cover_image?.url}
                      alt=""
                    />
                    <p className="mt-4 c-primary s-32 w4">
                      {NameLang(selectedDepartment, EN)}
                    </p>
                    <Grid container spacing={2}>
                      {selectedDepartment?.childrenData?.map((sub, i) => (
                        <Grid item xs={12} md={6} key={"sub" + sub.id}>
                          <Button
                            className="d-flex align-items-center w3 w-100
                             justify-content-between c-primary py-3 px-2 cursor_pointer"
                            style={{
                              border: "1px solid #2460B9",
                              height: "50px",
                            }}
                            onClick={(e) => handleClick(sub)}
                          >
                            <span>{NameLang(sub, EN)}</span>
                            <ArrowRight weight="bold" />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                    <p
                      className="mt-3 text-justify"
                      dangerouslySetInnerHTML={{
                        __html: EN
                          ? selectedDepartment.acf?.description_en
                          : selectedDepartment.acf?.description_np,
                      }}
                    />
                    {selectedDepartment.acf?.department_doctors &&
                      selectedDepartment.acf?.department_doctors.length > 0 && (
                        <p className="w4 s-24 mt-4">{OurDoctorsText(EN)}</p>
                      )}
                    {/* our doctors */}
                    {DoctorsArrDep(
                      EN,
                      selectedDepartment.acf?.department_doctors,
                      handleClickDoctor
                    )}
                    {/* our services */}
                    {selectedDepartment.acf?.department_services &&
                      selectedDepartment.acf?.department_services.length >
                        0 && (
                        <p className="w4 s-24 mt-4">{OurServicesText(EN)}</p>
                      )}
                    {DepServices(
                      EN,
                      selectedDepartment?.acf?.department_services,
                      handleClickService
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  );
};
