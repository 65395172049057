import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AboutUs, ContactUs } from "./Pages/AboutUs/AboutUs";
import { Department, Departments } from "./Pages/Department";
import { AllGallery, Gallery } from "./Pages/Gallery";
import { LandingPage } from "./Pages/LandingPage";
import { Layout } from "./Pages/Layout";
import { Downloads, NoticeBoard, OneDownload, OneNotice, OnePublication, Publications } from "./Pages/NoticeBoard";
import { Doctor, OurDoctors } from "./Pages/OurDoctors";
import { PageNotFound, ServerError } from "./Pages/PageNotFound";
import { Service, Services } from "./Pages/Services";
import { CitizenCharter } from "./Pages/AboutUs/CitizenCharter";
import { OrgStructure } from "./Pages/AboutUs/OrgStructure";
import { StaffDetail } from "./Pages/AboutUs/StaffDetail";
import { DocSchedule } from "./Pages/AboutUs/DocSchedule";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        let target = document.getElementById("scroll_here_id");
        if (target) target.scrollIntoView({ behavior: 'smooth' })
    }, [location]);

    return <>{props.children}</>
};

export const RoutesComp = () => {

    var root = document.getElementById('root')
    if (root) {
        root.style.scrollbarwith = 'thin'
    }
    return (
        <BrowserRouter
        >
            <ScrollToTop>
                <Routes>

                    <Route element={<Layout />}>
                        <Route path='/' element={<LandingPage />} />
                        <Route path='/departments' element={<Departments />} />
                        <Route path='/departments/view' element={<Department />} />
                        <Route path='/services' element={<Services />} />
                        <Route path='/services/view' element={<Service />} />
                        <Route path='/doctors' >
                            <Route path='' element={<OurDoctors />} />
                            <Route path='doctor/:id' element={<Doctor />} />
                        </Route>
                        <Route path='/gallery' element={<AllGallery />} />
                        <Route path='/gallery/:id' element={<Gallery />} />
                        <Route path='/notices' element={<NoticeBoard />} />
                        <Route path='/notice/:id' element={<OneNotice />} />
                        <Route path='/publications' element={<Publications />} />
                        <Route path='/publication/:id' element={<OnePublication />} />
                        <Route path='/downloads' element={<Downloads />} />
                        <Route path='/download/:id' element={<OneDownload />} />
                        <Route path='/about-us' element={<AboutUs />} />
                        <Route path='/citizen-charter' element={<CitizenCharter />} />
                        <Route path='/org-structure' element={<OrgStructure />} />
                        <Route path='/doc-schedule' element={<DocSchedule />} />
                        <Route path='/staff-detail' element={<StaffDetail />} />
                        <Route path='/contact-us' element={<ContactUs />} />

                    </Route>
                    <Route path='*' element={<PageNotFound />} />
                    <Route path='server-error' element={<ServerError />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter >
    )
}