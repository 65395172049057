import { Box, Container, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CitizenCharterText, HomeText } from "../../Components.js/Datasets";
import { TopComp, isMobile } from "../../Components.js/common";

export const CitizenCharter = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    loadingImage: true,
    data: [],
  });
  const { data, loadingImage } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[18],
      loading: false,
    }));
  }, [outletContext, EN]);
  return (
    <div>
      <TopComp
        title={CitizenCharterText(EN)}
        paths={[
          { name: HomeText(EN), val: "/" },
          { name: CitizenCharterText(EN) },
        ]}
        navigate={navigate}
      />
      <Container>
        {loadingImage && (
          <Skeleton
            className="mt-4"
            width="100%"
            style={{
              borderRadius: "12px",
            }}
            height={isMobile ? "200px" : "700px"}
            animation="wave"
            variant="rounded"
          />
        )}
        <div
          className="d-flex flex-column mt-4 align-items-center"
          onClick={(e) => setOpen(true)}
        >
          <img
            id="citizen_image"
            style={{ borderRadius: "12px" }}
            className={`w-100 smooth-image bg-white image-${
              !loadingImage ? "visible" : "hidden"
            }`}
            src={data?.acf?.image}
            onLoad={(e) => setValues((x) => ({ ...x, loadingImage: false }))}
            alt=""
          />
        </div>
        {/* modal */}
        {ImageModalZoom(open, setOpen, data?.acf?.image)}
      </Container>
    </div>
  );
};

export const ImageModalZoom = (open, setOpen, img) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   width: "80vw",
          //   height: "fit-content",
          maxWidth: "80vw",
          maxHeight: "95vh",
          overflowX: "hidden",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          border: "none",
          borderRadius: "5px",
          scrollbarWidth: "thin",
        }}
        width={{ xs: "80vw", md: "fit-content" }}
        height={{ xs: "fit-content", md: "auto" }}
      >
        {isMobile ? (
          <div className="bg-white">
            <img src={img} alt="" className="w-100 h-auto" />
          </div>
        ) : (
          <div className="w-100 h-100 bg-white">
            <img src={img} alt="" className="w-100 h-auto" />
          </div>
        )}
      </Box>
    </Modal>
  );
};
