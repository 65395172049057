import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Skeleton,
} from "@mui/material";
import { Envelope, MapPin, Phone } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { POSTAPIContact } from "../../Auth";
import { BaseUrl } from "../../Auth/config";
import {
  AboutUsText,
  AddressText,
  ContactUsText,
  EmailText,
  FullNameText,
  HomeText,
  MessageText,
  PhoneText,
  SubmitText,
} from "../../Components.js/Datasets";
import {
  AlertError,
  InputCompBook,
  InputCompEmailBook,
  TopComp,
  isMobile,
  isTextEmpty,
  sendAppointmentMail,
} from "../../Components.js/common";

export const AboutUs = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setValues] = useState({
    loadingImage: true,
    data: [],
  });
  const { data, loadingImage } = values;
  useEffect(() => {
    setValues((x) => ({
      ...x,
      data: outletContext[0],
      loading: false,
    }));
  }, [outletContext, EN]);
  return (
    <div>
      <TopComp
        title={AboutUsText(EN)}
        paths={[{ name: HomeText(EN), val: "/" }, { name: AboutUsText(EN) }]}
        navigate={navigate}
      />
      <Container>
        {loadingImage && (
          <Skeleton
            className="mt-4"
            width="100%"
            style={{
              borderRadius: "12px",
            }}
            height={isMobile ? "200px" : "700px"}
            animation="wave"
            variant="rounded"
          />
        )}
        <div className="d-flex flex-column mt-4 align-items-center">
          <img
            style={{ width: "100%", borderRadius: "12px" }}
            className={`w-100 smooth-image image-${
              !loadingImage ? "visible" : "hidden"
            }`}
            src={data.single_page_cover_image?.url}
            onLoad={(e) => setValues((x) => ({ ...x, loadingImage: false }))}
            alt=""
          />
          <p
            className="mt-3 text-justify h-100"
            dangerouslySetInnerHTML={{
              __html: EN
                ? data.single_page_description_en
                : data.single_page_description_np,
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export const ContactUs = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const EN = outletContext[11];
  const [values, setFn] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    errorPosition: "",
    errorText: "",
    loading: false,
  });
  const { name, email, phone, message, errorPosition, errorText, loading } =
    values;
  const validateSubmit = () => {
    if (isTextEmpty(name)) {
      setFn((x) => ({
        ...x,
        errorPosition: "name",
        errorText: "Invalid name",
      }));
      return false;
    } else if (isTextEmpty(phone)) {
      setFn((x) => ({
        ...x,
        errorPosition: "phone",
        errorText: "Invalid phone",
      }));
      return false;
    } else if (isTextEmpty(email)) {
      setFn((x) => ({
        ...x,
        errorPosition: "email",
        errorText: "Invalid email",
      }));
      return false;
    } else if (isTextEmpty(message)) {
      setFn((x) => ({
        ...x,
        errorPosition: "message",
        errorText: "Invalid message",
      }));
      return false;
    } else {
      setFn((x) => ({ ...x, errorPosition: "", errorText: "" }));
      return true;
    }
  };
  const handleSubmit = () => {
    if (validateSubmit()) {
      setFn((x) => ({ ...x, loading: true }));
      var formdata = new FormData();
      formdata.append("your-name", name);
      formdata.append("your-email", email);
      formdata.append("your-message", message);
      formdata.append("phone", phone);
      POSTAPIContact(
        `${BaseUrl}/wp-json/contact-form-7/v1/contact-forms/152/feedback`,
        formdata,
        navigate
      ).then((res) => {
        if (res.status === "mail_sent")
          setFn((x) => ({
            ...x,
            errorPosition: "success",
            errorText: "Your message has been sent",
            loading: false,
            name: "",
            email: "",
            phone: "",
            message: "",
            subject: "",
          }));
        else
          setFn((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.message,
            loading: false,
          }));
      });

      var app_send = {
        acf: {
          "your-name": name,
          "your-email": email,
          phone: phone,
          department: "",
          "your-message": message,
          type: "Contact Us",
        },
      };

      sendAppointmentMail(app_send);
    }
  };
  return (
    <Box>
      <TopComp
        title={ContactUsText(EN)}
        paths={[{ name: HomeText(EN), val: "/" }, { name: ContactUsText(EN) }]}
        navigate={navigate}
      />
      <Container className="p-md-0 py-5 mt-md-5">
        <Box className="d-flex flex-column flex-md-row mb-5">
          <Box className="col-12 col-md-6 mr-2">
            <p className="s-24 w3">
              {EN
                ? "Have any Questions or feedback?"
                : "के तपाईंसँग कुनै प्रश्न वा प्रतिक्रिया छ?"}
            </p>
            <p className="s-18 w3">
              {EN ? "Contact Us!" : "हामीलाई सम्पर्क गर्नुहोस्!"}
            </p>
            <Divider />
            <p className="s-16 mt-3 w-75 text-justify">
              {EN
                ? "We look forward to hearing from you and will do our best to assist you with any questions or concerns you may have."
                : "हामी तपाईंबाट सम्पर्क गर्न उत्सुक छौं र तपाईंको कुनै प्रश्न वा चिन्ता भएमा हामी तपाईंलाई सहयोग गर्न हामी श्रृङ्खलापूर्वक प्रयास गर्नेछौं।"}
            </p>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper style={{ boxShadow: "none" }}>
                  <div className="d-flex">
                    <Envelope size={25} />
                    <span className="ml-2 s-16">{EmailText(EN)}</span>
                  </div>
                  <span>hospitalayurveda@gmail.com</span>
                </Paper>
              </Grid>
              <Grid item md={12}>
                <Paper style={{ boxShadow: "none" }}>
                  <div className="d-flex">
                    <Phone size={25} />
                    <span className="ml-2 s-16">{PhoneText(EN)}</span>
                  </div>
                  <span>(+977)-1-4256883</span>
                </Paper>
              </Grid>
              <Grid item md={12}>
                <Paper style={{ boxShadow: "none" }}>
                  <div className="d-flex">
                    <MapPin size={25} />
                    <span className="ml-2 s-16">
                      {EN ? "Address" : "ठेगाना"}
                    </span>
                  </div>
                  <span>{AddressText(EN)}</span>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box
            width={{ xs: "100%", md: "600px" }}
            mt={{ xs: "30px", md: "0px" }}
          >
            <div className="d-flex">
              {InputCompBook(
                name,
                "name",
                setFn,
                "book_modal--input mr-2",
                FullNameText(EN),
                errorPosition,
                errorText
              )}
            </div>
            <div className="d-flex mt-2 mb-2">
              {InputCompBook(
                phone,
                "phone",
                setFn,
                "book_modal--input mr-2",
                PhoneText(EN),
                errorPosition,
                errorText
              )}
              {InputCompEmailBook(
                email,
                "email",
                setFn,
                "book_modal--input ml-2",
                EmailText(EN),
                errorPosition,
                errorText
              )}
            </div>
            <textarea
              type="text"
              value={message}
              placeholder={
                errorPosition === "message" ? errorText : MessageText(EN)
              }
              className={
                errorPosition === "message"
                  ? "c-error book_modal--textarea"
                  : "book_modal--textarea"
              }
              onChange={(e) =>
                setFn((prev) => ({
                  ...prev,
                  message: e.target.value,
                  errorPosition: "",
                }))
              }
            />
            <Button className="primary_btn w-100 mt-1" onClick={handleSubmit}>
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} />
              ) : (
                SubmitText(EN)
              )}
            </Button>
          </Box>
        </Box>
        {/* map */}
        <Box width={"100%"}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.3084378645394!2d85.30310591545349!3d27.70776158207971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18f9ad72005f%3A0x2188fbf327f5d892!2sNardevi%20Ayurvedic%20Hospital%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1678014263043!5m2!1sen!2snp"
            width="100%"
            height="500px"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </Box>
      </Container>
      {AlertError(errorPosition, errorText, setFn)}
    </Box>
  );
};
