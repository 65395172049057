import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { ArrowRight, CaretLeft, CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TwitterTweetEmbed } from "react-twitter-embed";
import {
  AboutUsText,
  AboutUsTextHome,
  BookAppointmentText,
  DownloadsText,
  NoticeBoardText,
  OurDepartmentsText,
  OurDoctorsText,
  PublicationsText,
  ReadMoreText,
  ViewAllText,
  tweets_ID,
} from "../Components.js/Datasets";
import {
  AlertError,
  CarouAnt,
  DoctorsArrland,
  DoctorsArrlandSke,
  NameLang,
  isMobile,
} from "../Components.js/common";
import "../css/fonts.css";
import "../css/landing.css";
import "../css/layout.css";

export const LandingPage = () => {
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const setFn = outletContext[10];
  const EN = outletContext[11];
  const loading = outletContext[14];
  const employee = outletContext[15];
  const loadingSecond = outletContext[16];
  const hotlines = outletContext[17];
  const [values, setValues] = useState({
    first: true,
    errorPosition: "",
    errorText: "",
    test: [],
    aboutUs: {},
    aboutUsLoaded: false,
    ourDepartments: [],
    servicesIcons: {},
    ourDoctors: [],
    gallery: [],
    noticeBoard: [],
    publications: [],
    downloads: [],
    slider: [],
    videos: [],
    tweets: [],
  });
  const {
    first,
    errorPosition,
    errorText,
    aboutUs,
    aboutUsLoaded,
    ourDepartments,
    ourDoctors,
    noticeBoard,
    publications,
    downloads,
    slider,
    videos,
    tweets,
  } = values;
  const handleDepClick = (clickedDep) => {
    navigate("/departments/view", { state: clickedDep });
  };
  useEffect(() => {
    if (first && !loading)
      setValues((x) => ({
        ...x,
        first: false,
        aboutUs: outletContext[0],
        ourDepartments: outletContext[1],
        ourDoctors: outletContext[2],
        noticeBoard: outletContext[3],
        publications: outletContext[4],
        downloads: outletContext[5],
        slider: outletContext[6],
        videos: outletContext[8],
        popup: outletContext[9],
        tweets: outletContext[13],
      }));
  }, [outletContext, first, loading]);
  const handleScroll = (right) => {
    const doc = document.getElementById("doctor_images");
    if (doc)
      if (right) doc.scrollLeft += 200;
      else doc.scrollLeft -= 200;
  };
  const handleViewAll = (dest) => {
    navigate(dest);
  };
  //   const handleCLickGallery = (clicked) => {
  //     navigate("/gallery/" + clicked.id, { state: clicked });
  //   };
  return (
    <div>
      {AlertError(errorPosition, errorText, setValues)}
      {/* carousel */}
      {isMobile ? (
        <CarouAnt
          slide={slider}
          EN={EN}
          employee={employee}
          hotlines={hotlines}
        />
      ) : (
        <Container style={{ marginTop: "16px" }}>
          <CarouAnt
            slide={slider}
            EN={EN}
            employee={employee}
            loadingSecond={loadingSecond}
            hotlines={hotlines}
          />
        </Container>
      )}
      {/* about us */}
      {loading ? (
        <Container>
          <p className="d-md-none s-32 w3 mt-4 c-primary">{AboutUsText(EN)}</p>
          <Box
            height={{ xs: "auto", md: "600px" }}
            width={{ xs: "100%", md: "100%" }}
            className="d-flex flex-column-reverse flex-md-row ml-auto align-items-center"
          >
            <Box
              width={{ xs: "100%", md: "calc(100% - 670px)" }}
              className="d-flex flex-column"
              gap={"24px"}
            >
              <Box className="d-flex flex-column" gap={"16px"}>
                <p className="d-none d-md-block s-32 w3 mb-0 c-primary">
                  {AboutUsTextHome(EN)}
                </p>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"250px"}
                />
              </Box>
              <Box width={{ xs: "100%", md: "50%" }}>
                <Button
                  className="primary_btn w-100"
                  onClick={(e) => handleViewAll("about-us")}
                >
                  {ReadMoreText(EN)}
                </Button>
              </Box>
            </Box>
            <Skeleton
              variant="rectangular"
              width={"600px"}
              height="450px"
              style={{
                borderRadius: "12px",
              }}
              className="ml-auto d-none d-md-flex"
            ></Skeleton>
          </Box>
        </Container>
      ) : (
        <Container style={{ marginTop: "80px" }}>
          <p className="d-md-none s-32 w3">{AboutUsTextHome(EN)}</p>
          <Box
            height={{ xs: "auto", md: "auto" }}
            width={{ xs: "100%", md: "100%" }}
            className="d-flex flex-column flex-md-row ml-auto align-items-center"
          >
            {/* description */}
            <Box
              width={{ xs: "100%", md: "calc(100% - 450px )" }}
              className="d-flex flex-column align-self-cente mr-md-4"
              gap={"24px"}
            >
              <Box className="d-flex flex-column" gap="16px">
                <p className="d-none d-md-block s-40 w3 mb-0 c-primary">
                  {AboutUsTextHome(EN)}
                </p>
                <p
                  style={{ textAlign: "justify", textJustify: "inter-word" }}
                  className="s-16 mb-0"
                  dangerouslySetInnerHTML={{
                    __html: EN
                      ? aboutUs?.home_page_description_en
                      : aboutUs?.home_page_description_np,
                  }}
                />
              </Box>
              <Box width={{ xs: "100%", md: "max-content" }}>
                <Button
                  className="primary_btn w-100 w-md-auto"
                  onClick={(e) => handleViewAll("about-us")}
                >
                  {ReadMoreText(EN)}
                </Button>
              </Box>
            </Box>
            {/* image */}
            {/* <Box
              width={{ xs: "95vw", md: "600px" }}
              className="ml-auto"
              height={{ xs: "300px", md: "386px" }}
              mb={{ xs: "10px", md: "" }}
              borderRadius={"12px"}
              sx={{
                background: `url(${aboutUs?.homepage_image?.url}) no-repeat center center`,
                // backgroundRepeat: "no-repeat",
                backgroundSize: "auto 100%",
              }}
            >
              <img
                src={aboutUs?.homepage_image?.url}
                alt="background"
                style={{ display: "none" }}
                onLoad={(e) =>
                  setValues((x) => ({ ...x, aboutUsLoaded: true }))
                }
              />
              {!aboutUsLoaded && (
                <Skeleton width="100%" height="100%" variant="rectangular" />
              )}
            </Box> */}
            {/* news */}
            <Grid item xs={"100%"} md={"450px"}>
              <Paper className="notice_board">
                <p className="c-primary s-24 w3 mb-0">{NoticeBoardText(EN)}</p>
                <div
                  style={{
                    width: "57px",
                    height: "2px",
                    backgroundColor: "#CC2A34",
                  }}
                />
                {loading ? (
                  <div className="mb-2 mt-3">
                    {[1, 2, 3].map((each, i) => {
                      return (
                        <div
                          key={"skel_notice" + each}
                          className="d-flex flex-column cursor_pointer"
                        >
                          <Skeleton />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="mb-2 mt-3">
                    {noticeBoard.map((each, i) => {
                      if (i > 3) return null;
                      return (
                        <div
                          key={each.id}
                          className="d-flex flex-column cursor_pointer"
                          onClick={(e) =>
                            navigate("/notice/" + each.id, {
                              state: each,
                            })
                          }
                        >
                          <span className="name_two_lines">
                            {NameLang(each, EN)}
                          </span>
                          <span className="c-gray">
                            {each.date.split("T")[0]}
                          </span>
                          <div style={{ borderBottom: "1px solid #EFEFEF" }} />
                        </div>
                      );
                    })}
                  </div>
                )}
                {!loading && (
                  <Button
                    className="view_all_btn mt-auto mr-auto"
                    onClick={(e) => handleViewAll("notices")}
                  >
                    <span className="mr-2">{ViewAllText(EN)}</span>{" "}
                    <ArrowRight weight="bold" />
                  </Button>
                )}
              </Paper>
            </Grid>
          </Box>
        </Container>
      )}
      {/* our departments */}
      {loading ? (
        <Container className="d-flex mt-5 flex-column align-items-center">
          <p className="w3 s-32 ml-auto mb-4 mr-auto">
            {OurDepartmentsText(EN)}
          </p>
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((each, i) => (
              <Grid key={each.id} item xs={6} md={2.4}>
                <Paper className="our_services_paper p-0 cursor_pointer">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height="100%"
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Button
            className="primary_btn mt-5 mb-5"
            onClick={(e) => setFn((x) => ({ ...x, openBook: true }))}
          >
            {BookAppointmentText(EN)}
          </Button>
        </Container>
      ) : (
        <Container
          className="d-flex flex-column align-items-center"
          style={{ marginTop: "80px" }}
        >
          <p className="w3 s-32 ml-auto mb-5 mr-auto">
            {OurDepartmentsText(EN)}
          </p>
          <Grid container spacing={2}>
            {ourDepartments.map((each, i) => (
              <Grid
                key={each.id}
                item
                xs={6}
                md={2.4}
                onClick={(e) => handleDepClick(each)}
              >
                <Paper className="our_services_paper justify-content-start cursor_pointer">
                  <Avatar
                    src={each.acf?.icon.url}
                    alt=""
                    variant="square"
                    style={{ width: "75px", height: "75px" }}
                  />
                  <Tooltip title={NameLang(each, EN)}>
                    <span className="w3 mt-2 text-center name_two_lines h-100">
                      {NameLang(each, EN)}
                    </span>
                  </Tooltip>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Button
            className="primary_btn mt-5 mb-5"
            onClick={(e) => setFn((x) => ({ ...x, openBook: true }))}
          >
            {BookAppointmentText(EN)}
          </Button>
        </Container>
      )}
      {/* doctors */}
      {loading ? (
        <Container className="d-flex flex-column mt-md-5 overflow-hidden">
          <div className="d-flex">
            <span className="w3 s-32">{OurDoctorsText(EN)}</span>
            <Button
              className="view_all_btn ml-auto"
              onClick={(e) => handleViewAll("doctors")}
            >
              <span className="mr-2">{ViewAllText(EN)}</span>
              <ArrowRight weight="bold" />
            </Button>
          </div>
          <div className="our_doctors">
            {ourDoctors.length > 5 && (
              <IconButton
                className="our_doctors-icon"
                onClick={() => handleScroll(false)}
              >
                <CaretLeft color="#fff" />
              </IconButton>
            )}
            {/* small screeen */}
            <Grid container spacing={2} className="d-md-none">
              {[1, 2, 3, 4].map((each, i) => (
                <Grid
                  key={each.id}
                  item
                  xs={6}
                  md={2.4}
                  onClick={(e) => handleDepClick(each)}
                >
                  <Paper className="our_services_paper p-0 cursor_pointer">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height="100%"
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {/* bigger */}
            {DoctorsArrlandSke(EN, [1, 2, 3, 4], navigate, loading)}
          </div>
        </Container>
      ) : (
        <Container className="d-flex flex-column mt-md-5 overflow-hidden">
          <div className="d-flex">
            <span className="w3 s-32">{OurDoctorsText(EN)}</span>
            <Button
              className="view_all_btn ml-auto"
              onClick={(e) => handleViewAll("doctors")}
            >
              <span className="mr-2">{ViewAllText(EN)}</span>
              <ArrowRight weight="bold" />
            </Button>
          </div>
          <div className="our_doctors">
            {ourDoctors.length > 5 && (
              <IconButton
                className="our_doctors-icon"
                onClick={() => handleScroll(false)}
              >
                <CaretLeft color="#fff" />
              </IconButton>
            )}
            {DoctorsArrland(EN, ourDoctors, navigate)}
            {ourDoctors.length > 5 && (
              <IconButton
                className="our_doctors-icon ml-auto"
                onClick={() => handleScroll(true)}
              >
                <CaretRight color="#fff" />
              </IconButton>
            )}
          </div>
        </Container>
      )}
      {/* gallery */}
      {/* <Container className="d-flex flex-column mt-5">
        <div className="d-flex mb-4">
          <span className="w3 s-32">{GalleryText(EN)}</span>
          {!loading && (
            <Button
              className="view_all_btn ml-auto"
              onClick={(e) => handleViewAll("gallery")}
            >
              <span className="mr-2">{ViewAllText(EN)}</span>
              <ArrowRight weight="bold" />
            </Button>
          )}
        </div>
        {loading ? (
          <Box className="d-flex">
            <Box className="d-flex flex-column flex-md-row w-100 grid">
              <div className="container_test gallery_one border-0 d-flex flex-column">
                <Box
                  width={{ xs: "100%", md: "462px" }}
                  className={`d-block h-100 smooth-image}`}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              </div>
              <Box className="w-100">
                <Box className="d-flex justify-content-between">
                  <div className="container_test gallery_right border-0 d-flex flex-column">
                    <Box
                      width={{ xs: "100%", md: "305px" }}
                      className={`d-block h-100 smooth-image gallery_right}`}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                  </div>
                  <div className="container_test gallery_right border-0 d-flex flex-column">
                    <Box
                      width={{ xs: "100%", md: "305px" }}
                      className={`d-block h-100 smooth-image gallery_right}`}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                  </div>
                </Box>
                <Box className="d-flex justify-content-between">
                  <div
                    className="container_test gallery_right border-0 d-flex flex-column"
                    onClick={(e) => handleCLickGallery(gallery[3])}
                  >
                    <Box
                      width={{ xs: "100%", md: "305px" }}
                      className={`d-block h-100 smooth-image gallery_right}`}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                  </div>
                  <div
                    className="container_test gallery_right border-0 d-flex flex-column"
                    onClick={(e) => handleCLickGallery(gallery[4])}
                  >
                    <Box
                      width={{ xs: "100%", md: "305px" }}
                      className={`d-block h-100 smooth-image gallery_right}`}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="d-flex">
            {gallery && gallery.length > 0 && (
              <Box className="d-flex flex-column flex-md-row w-100 grid">
                {gallery[0] && (
                  <Box
                    className="container_test gallery_one border-0 d-flex flex-column overflow-hidden"
                    minWidth={{ xs: "100%", md: "462px" }}
                    onClick={(e) => handleCLickGallery(gallery[0])}
                    style={{
                      borderRadius: "12px",
                    }}
                  >
                    <Box
                      width={{ xs: "100%", md: "462px" }}
                      className={`d-block h-100 smooth-image}`}
                      sx={{
                        backgroundImage: `url(${gallery[0]?.acf?.thumbnail})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "12px",
                      }}
                    ></Box>
                    <div className="bottom-left ellipsis px-1">
                      {NameLang(gallery[0], EN)}
                    </div>
                  </Box>
                )}
                <Box className="w-100">
                  <Box className="d-flex justify-content-between">
                    {gallery[1] && (
                      <div
                        className="container_test gallery_right border-0 d-flex flex-column"
                        onClick={(e) => handleCLickGallery(gallery[1])}
                        style={{
                          borderRadius: "12px",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          width={{ xs: "100%", md: "305px" }}
                          className={`d-block h-100 smooth-image gallery_right}`}
                          sx={{
                            backgroundImage: `url(${gallery[1]?.acf?.thumbnail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                        <div className="bottom-left ellipsis px-1">
                          {NameLang(gallery[1], EN)}
                        </div>
                      </div>
                    )}
                    {gallery[2] && (
                      <div
                        className="container_test gallery_right border-0 d-flex flex-column"
                        onClick={(e) => handleCLickGallery(gallery[2])}
                        style={{
                          borderRadius: "12px",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          width={{ xs: "100%", md: "305px" }}
                          className={`d-block h-100 smooth-image gallery_right}`}
                          sx={{
                            backgroundImage: `url(${gallery[2]?.acf?.thumbnail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                        <div className="bottom-left ellipsis px-1">
                          {NameLang(gallery[2], EN)}
                        </div>
                      </div>
                    )}
                  </Box>
                  <Box className="d-flex justify-content-between">
                    {gallery[3] && (
                      <div
                        className="container_test gallery_right border-0 d-flex flex-column"
                        onClick={(e) => handleCLickGallery(gallery[3])}
                        style={{
                          borderRadius: "12px",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          width={{ xs: "100%", md: "305px" }}
                          className={`d-block h-100 smooth-image gallery_right}`}
                          sx={{
                            backgroundImage: `url(${gallery[3]?.acf?.thumbnail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                        <div className="bottom-left ellipsis px-1">
                          {NameLang(gallery[3], EN)}
                        </div>
                      </div>
                    )}
                    {gallery[4] && (
                      <div
                        className="container_test gallery_right border-0 d-flex flex-column"
                        onClick={(e) => handleCLickGallery(gallery[4])}
                        style={{
                          borderRadius: "12px",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          width={{ xs: "100%", md: "305px" }}
                          className={`d-block h-100 smooth-image gallery_right}`}
                          sx={{
                            backgroundImage: `url(${gallery[4]?.acf?.thumbnail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        ></Box>
                        <div className="bottom-left ellipsis px-1">
                          {NameLang(gallery[4], EN)}
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container> */}
      {/* notice board */}
      {/* <Container className="d-flex flex-column mt-3 mt-md-5">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper className="notice_board">
              <p className="c-primary s-24 w3 mb-0">{NoticeBoardText(EN)}</p>
              <div
                style={{
                  width: "57px",
                  height: "2px",
                  backgroundColor: "#CC2A34",
                }}
              />
              {loading ? (
                <div className="mb-2 mt-3">
                  {[1, 2, 3].map((each, i) => {
                    return (
                      <div
                        key={"skel_notice" + each}
                        className="d-flex flex-column cursor_pointer"
                      >
                        <Skeleton />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mb-2 mt-3">
                  {noticeBoard.map((each, i) => {
                    if (i > 3) return null;
                    return (
                      <div
                        key={each.id}
                        className="d-flex flex-column cursor_pointer"
                        onClick={(e) =>
                          navigate("/notice/" + each.id, {
                            state: each,
                          })
                        }
                      >
                        <span className="name_two_lines">
                          {NameLang(each, EN)}
                        </span>
                        <span className="c-gray">
                          {each.date.split("T")[0]}
                        </span>
                        <div style={{ borderBottom: "1px solid #EFEFEF" }} />
                      </div>
                    );
                  })}
                </div>
              )}
              {!loading && (
                <Button
                  className="view_all_btn mt-auto mr-auto"
                  onClick={(e) => handleViewAll("notices")}
                >
                  <span className="mr-2">{ViewAllText(EN)}</span>{" "}
                  <ArrowRight weight="bold" />
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="notice_board">
              <p className="c-primary s-24 w3 mb-0">{PublicationsText(EN)}</p>
              <div
                style={{
                  width: "57px",
                  height: "2px",
                  backgroundColor: "#CC2A34",
                }}
              />
              {loading ? (
                <div className="mb-2 mt-3">
                  {[1, 2, 3].map((each, i) => {
                    if (i > 6) return null;
                    return (
                      <div
                        key={each.id}
                        className="d-flex flex-column cursor_pointer"
                      >
                        <Skeleton />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mb-2 mt-3">
                  {publications.map((each, i) => {
                    if (i > 6) return null;
                    return (
                      <div
                        key={each.id}
                        className="d-flex flex-column cursor_pointer"
                        onClick={(e) =>
                          navigate("/publication/" + each.id, {
                            state: each,
                          })
                        }
                      >
                        <span className="name_two_lines">
                          {NameLang(each, EN)}
                        </span>
                        <div
                          style={{
                            margin: "10px 0",
                            borderBottom: "1px solid #EFEFEF",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!loading && (
                <Button
                  className="view_all_btn mr-auto mt-auto"
                  onClick={(e) => handleViewAll("publications")}
                >
                  <span className="mr-2">{ViewAllText(EN)}</span>{" "}
                  <ArrowRight weight="bold" />
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="notice_board">
              <p className="c-primary s-24 w3 mb-0">{DownloadsText(EN)}</p>
              <div
                style={{
                  width: "57px",
                  height: "2px",
                  backgroundColor: "#CC2A34",
                }}
              />
              {loading ? (
                <div className="mb-2 mt-3">
                  {[1, 2, 3].map((each, i) => {
                    return (
                      <div
                        key={each.id}
                        className="d-flex flex-column cursor_pointer"
                      >
                        <Skeleton />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="mb-2 mt-3">
                  {downloads.map((each, i) => {
                    if (i > 6) return null;
                    return (
                      <div
                        key={each.id}
                        className="d-flex flex-column cursor_pointer"
                        onClick={(e) =>
                          navigate("/download/" + each.id, {
                            state: each,
                          })
                        }
                      >
                        <span className="name_two_lines">
                          {NameLang(each, EN)}
                        </span>
                        <div
                          style={{
                            margin: "10px 0",
                            borderBottom: "1px solid #EFEFEF",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!loading && (
                <Button
                  className="view_all_btn mr-auto mt-auto"
                  onClick={(e) => handleViewAll("downloads")}
                >
                  <span className="mr-2">{ViewAllText(EN)}</span>{" "}
                  <ArrowRight weight="bold" />
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container> */}
      {/* video */}
      <Container className="mt-3 mt-md-5 mb-5">
        <Box className="d-flex flex-column flex-md-row">
          {/* video */}
          {loading ? (
            <Box
              width={{ xs: "100%", md: "740px" }}
              height={{ xs: "auto", md: "436px" }}
              borderRadius="12px"
              className="mt-md-auto"
              mb={{ xs: "20px", md: "0" }}
            >
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
          ) : (
            <Box
              width={{ xs: "100%", md: "740px" }}
              height={{ xs: "auto", md: "436px" }}
              borderRadius="12px"
              className="mt-md-auto"
              mb={{ xs: "20px", md: "0" }}
            >
              {videos[0] && videos[0].acf.video_link.includes("youtube") ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={videos?.[0]?.acf?.video_link?.replace(
                    "watch?v=",
                    "embed/"
                  )}
                  title="YouTube video player"
                  style={{
                    borderRadius: "12px",
                  }}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              ) : (
                <CardMedia
                  component={"video"}
                  width="100%"
                  style={{ borderRadius: "12px" }}
                  height={"100%"}
                  controls
                  image={videos[0] && videos[0].acf.video_link}
                />
              )}
            </Box>
          )}

          {/* tweets */}
          {tweets && tweets?.length > 0 ? (
            <Box
              className="ml-md-3"
              width={{ xs: "100%", md: "calc(100% - 750px)" }}
            >
              <span className="s-18 w3">
                {EN ? (
                  <span>
                    Tweets from <span className="c-primary">@nardevi</span>
                  </span>
                ) : (
                  <span>
                    <span className="c-primary">@nardevi</span> बाट ट्विटहरू
                  </span>
                )}
              </span>
              {/* tweet */}
              <Box
                height={{ xs: "auto", md: "420px" }}
                sx={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                {tweets?.map((each, i) => (
                  <TwitterTweetEmbed
                    key={each}
                    tweetId={each}
                    //   tweetId={"1638381090368012289"}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              className="ml-md-3"
              width={{ xs: "100%", md: "calc(100% - 750px)" }}
            >
              <span className="s-18 w3">
                {EN ? (
                  <span>
                    Tweets from <span className="c-primary">@nardevi</span>
                  </span>
                ) : (
                  <span>
                    <span className="c-primary">@nardevi</span> बाट ट्विटहरू
                  </span>
                )}
              </span>
              {/* tweet */}
              <Box
                height={{ xs: "auto", md: "420px" }}
                sx={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                {tweets_ID?.map((each, i) => (
                  <TwitterTweetEmbed
                    key={each}
                    tweetId={each}
                    // tweetId={"1638381090368012289"}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};
